import React from "react"
import styled from "styled-components"

import { breakpoints } from "@breakpoints"

const StyledMarginWrapper = styled.section<styledProps>`
    position: relative;
    margin: ${({ margin }) => (margin ? "30px 0px 30px 0" : "0px")};
    ${breakpoints.lg} {
        margin: ${({ margin }) => (margin ? "50px 0 50px 0" : "0px")};
    }
`

const WithMargin = (Component: React.FC<any>) => {
    return function WithMarginComponent({ ...props }) {
        return (
            <StyledMarginWrapper margin={props.acf.margins}>
                <Component {...props} />
            </StyledMarginWrapper>
        )
    }
}

export default WithMargin

interface styledProps {
    margin: boolean
}
