import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { removeLocalStorage } from "../../../../utils/localStorage"

const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;
`

const Item = styled.li<{ active: boolean }>`
    color: ${({ active }) => (active ? "var(--light-blue)" : "var(--dark)")};
    font-weight: 700;
    margin-bottom: 25px;
    cursor: pointer;

    &:hover {
        color: var(--light-blue);
    }
`

const Menu = ({ setUserData, items, active, setActive }: props) => {
    const logOut = () => {
        setUserData(false)
        removeLocalStorage("user")
        removeLocalStorage("orders")

        navigate("/")
    }

    return (
        <Wrapper>
            {items &&
                items.map(item => (
                    <Item
                        key={item}
                        active={item === active}
                        onClick={() => setActive(item)}
                        dangerouslySetInnerHTML={{ __html: item }}
                    />
                ))}
            <Item
                data-cy="logoutButton"
                active={false}
                onClick={() => logOut()}
                dangerouslySetInnerHTML={{ __html: "Wyloguj się" }}
            />
        </Wrapper>
    )
}

export default Menu

interface props {
    items: Array<string>
    setUserData: (userData: any) => void
    setActive: (activeLink: string) => void
    active: string
}
