import { createElement } from "react"
import { config } from "./config"

const SectionsParser = ({ templates }: any = []) => {
    const orderSections = templates.sort(
        (a: any = {}, b: any = {}) => a.menuOrder - b.menuOrder
    )

    const parsedSections = orderSections.map(
        (section: any = {}, index: number) => {
            const slug = section.acf.template
            const margins = section.acf.margins
            if (config[slug]) {
                return createElement(config[slug].section, {
                    key: slug + index,
                    acf: { ...section[config[slug].acf], margins },
                })
            } else {
                return
            }
        }
    )

    return parsedSections
}

export default SectionsParser
