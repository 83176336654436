import React, { useState, useContext } from "react"
import styled from "styled-components"
import { UserContext } from "../../../context/UserContext"
import { H2 } from "../../../styled/headers"
import Input from "../../../common/form/Input"
import { Button } from "../../../common/Button"
import axios from "../../../../utils/axios"
import {
    decryptPassword,
    encryptPassword,
} from "../../../../utils/bcryptPassword"
import { validatePasswordStrength } from "../../../../utils/validatePasswordStrength"
import { setLocalStorage } from "../../../../utils/localStorage"
import { metaData } from "@types"

const From = styled.form`
    max-width: 500px;
    margin-top: 30px;
`

const Wrapper = styled.div`
    margin-top: 50px;
`

const Response = styled.div<{ error: boolean }>`
    font-weight: 700;
    font-size: var(--base-font);
    color: ${({ error }) => (error ? "var(--red)" : "var(--green)")};
    margin-top: 10px;
`

const PasswordRequirements = styled.span`
    display: block;
    color: var(--gray);
    font-size: var(--base-font);
    margin-top: -25px;
    margin-bottom: 25px;
`

const ResetPassword = ({
    data: {
        buttonText,
        errorMessage,
        header,
        newPasswordLabel,
        newPasswordPlaceholder,
        repeatPasswordLabel,
        repeatPasswordPlaceholder,
        successfullMessage,
        currentPasswordLabel,
        currentPasswordPlaceholder,
        newPasswordEqualsCurrentError,
        invalidCurrentPasswordError,
        passwordRequirements,
        passwordsNotEqualError,
    },
}: props) => {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({
        message: "",
        error: false,
    })
    const [currentPassword, setCurrentPassword] = useState({
        value: "",
        error: false,
    })
    const [newPassword, setNewPassword] = useState({
        value: "",
        error: false,
    })
    const [repeatNewPassword, setRepeatNewPassword] = useState({
        value: "",
        error: false,
    })

    const { userData, setUserData } = useContext<any>(UserContext)

    const deHash = (passwordToCheck: string) => {
        let hashPassword = ""
        userData.meta_data.forEach(({ key, value }: metaData) => {
            if (key === "password") {
                hashPassword = value
            }
        })

        return decryptPassword(passwordToCheck, hashPassword)
    }

    const prepareRequest = (e: React.FormEvent) => {
        e.preventDefault()
        setResponse({
            message: "",
            error: false,
        })
    }

    const validateFields = (condition: boolean, message: string) => {
        if (condition) {
            setResponse({
                message,
                error: true,
            })
            return false
        }
        return true
    }

    const handleReset = (e: React.FormEvent) => {
        prepareRequest(e)
        let valid = true

        const toValidate = [
            {
                condition: newPassword.value !== repeatNewPassword.value,
                message: passwordsNotEqualError,
            },
            {
                condition: !deHash(currentPassword.value),
                message: invalidCurrentPasswordError,
            },
            {
                condition: !validatePasswordStrength(newPassword.value),
                message: passwordRequirements,
            },
            {
                condition: deHash(newPassword.value),
                message: newPasswordEqualsCurrentError,
            },
        ]

        toValidate.forEach(({ condition, message }) => {
            if (valid) {
                valid = validateFields(condition, message)
            }
        })

        if (!valid) return

        setLoading(true)
        axios
            .post(`customers/${userData.id}`, {
                meta_data: [
                    {
                        key: "password",
                        value: encryptPassword(newPassword.value),
                    },
                ],
            })
            .then(response => {
                setLocalStorage("user", JSON.stringify(response.data))
                setUserData(response.data)
                setLoading(false)
                setResponse({
                    message: successfullMessage,
                    error: false,
                })
            })
            .catch(error => {
                setLoading(false)
                console.error(error)
                setResponse({
                    message: errorMessage,
                    error: error,
                })
            })
    }

    return (
        <Wrapper>
            <H2 dangerouslySetInnerHTML={{ __html: header }} />
            <From onSubmit={handleReset}>
                <Input
                    id="current-password-reset"
                    required
                    onChange={e =>
                        setCurrentPassword({
                            value: e,
                            error: false,
                        })
                    }
                    error={currentPassword.error}
                    type="password"
                    value={currentPassword.value}
                    label={currentPasswordLabel}
                    placeholder={currentPasswordPlaceholder}
                />
                <Input
                    id="new-password-reset"
                    required
                    error={newPassword.error}
                    onChange={e => setNewPassword({ value: e, error: false })}
                    type="password"
                    value={newPassword.value}
                    label={newPasswordLabel}
                    placeholder={newPasswordPlaceholder}
                />
                <PasswordRequirements
                    dangerouslySetInnerHTML={{
                        __html: passwordRequirements,
                    }}
                />
                <Input
                    id="repeat-new-password-reset"
                    required
                    error={repeatNewPassword.error}
                    onChange={e =>
                        setRepeatNewPassword({
                            value: e,
                            error: false,
                        })
                    }
                    type="password"
                    value={repeatNewPassword.value}
                    label={repeatPasswordLabel}
                    placeholder={repeatPasswordPlaceholder}
                />
                <Button disabled={loading} loading={loading}>
                    {buttonText}
                </Button>
            </From>
            <Response error={response.error}>{response.message}</Response>
        </Wrapper>
    )
}

export default ResetPassword

interface props {
    data: {
        buttonText: string
        errorMessage: string
        header: string
        newPasswordLabel: string
        newPasswordPlaceholder: string
        repeatPasswordLabel: string
        repeatPasswordPlaceholder: string
        successfullMessage: string
        currentPasswordLabel: string
        currentPasswordPlaceholder: string
        newPasswordEqualsCurrentError: string
        invalidCurrentPasswordError: string
        passwordRequirements: string
        passwordsNotEqualError: string
    }
}
