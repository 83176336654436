import React from "react"
import styled from "styled-components"
import { H4 } from "../../styled/headers"
import { Button } from "../../common/Button"
import Input from "../../common/form/Input"

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`

const StyledButtonWrapper = styled.div`
    display: flex;
    margin-bottom: 30px;
    white-space: nowrap;
    margin-left: 20px;
`
const StyledResponse = styled.span<{ error: boolean }>`
    display: inline-block;
    font-weight: bold;
    color: ${({ error }) => (error ? "var(--red)" : "var(--green)")};
    margin-bottom: 20px;
`
const Coupon = ({
    header,
    buttonText,
    coupon,
    setCoupon,
    applyCoupon,
    placeholder,
    isLoading,
    applyingCoupon,
    response,
}: props) => {
    return (
        <div>
            <H4 small dangerouslySetInnerHTML={{ __html: header }} />
            <StyledWrapper>
                <Input
                    value={coupon}
                    type="text"
                    id="coupon"
                    onChange={e => setCoupon(e)}
                    placeholder={placeholder}
                />
                <StyledButtonWrapper>
                    <Button
                        disabled={applyingCoupon}
                        onClick={() => applyCoupon()}
                        loading={isLoading}
                    >
                        {buttonText}
                    </Button>
                </StyledButtonWrapper>
            </StyledWrapper>

            <StyledResponse
                error={response.type === "error"}
                dangerouslySetInnerHTML={{ __html: response.message }}
            />
        </div>
    )
}

export default Coupon

interface props {
    header: string
    buttonText: string
    coupon: string | undefined
    setCoupon: (coupon: string) => void
    placeholder: string
    applyCoupon: () => void
    isLoading: boolean
    applyingCoupon: boolean
    response: {
        type: "success" | "error" | ""
        message: string
    }
}
