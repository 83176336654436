import React, { useState } from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"

const WrapperOuter = styled.div`
    cursor: pointer;
    border: 1px solid var(--light-gray);
    border-radius: 10px;
    overflow: hidden;

    ${breakpoints.md} {
        border: none;
    }
`

const Wrapper = styled.div<{ expanded: boolean }>`
    display: ${({ expanded }) => (expanded ? "flex" : "none")};
    flex-direction: column;

    ${breakpoints.md} {
        display: flex;
    }
`

const Selected = styled.div`
    position: relative;
    width: 100%;
    background-color: #fff;
    color: var(--light-blue);
    padding: 10px;
    font-weight: 700;

    ${breakpoints.md} {
        display: none;
    }

    &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid var(--medium-black);
    }
`

const Item = styled.div<{ active: boolean; disabled: boolean }>`
    position: relative;
    display: block;
    background-color: #fff;
    padding: 10px 40px 10px 10px;
    border-top: 1px solid var(--light-gray);
    font-weight: 700;

    color: ${({ active }) =>
        active ? "var(--light-blue)" : "var(--medium-black)"};
    opacity: ${({ disabled }) => (disabled ? ".5" : "1")};

    ${breakpoints.md} {
        background-color: transparent;
        border: none;
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        color: var(--light-blue);

        &:after {
            border-left: 8px solid var(--light-blue);
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: ${({ active }) =>
            active
                ? "8px solid var(--light-blue)"
                : "8px solid var(--medium-black)"};
    }
`

const Menu = ({ items, setActive, active }: props) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <WrapperOuter>
            <Selected
                dangerouslySetInnerHTML={{ __html: active }}
                onClick={() => setExpanded(!expanded)}
            />
            <Wrapper expanded={expanded}>
                {items?.map(({ id, title, disabled }) => (
                    <Item
                        key={id}
                        disabled={disabled}
                        active={title === active}
                        dangerouslySetInnerHTML={{ __html: title }}
                        onClick={() => {
                            setExpanded(false)
                            setActive(title)
                        }}
                    />
                ))}
            </Wrapper>
        </WrapperOuter>
    )
}

export default Menu

interface props {
    items: Array<{ id: string; title: string; disabled: boolean }>
    setActive: (id: string) => void
    active: string
}
