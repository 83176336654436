import styled from "styled-components"

const Response = styled.span<{ error?: boolean }>`
    display: block;
    margin: 20px 0;
    font-size: var(--base-font);
    font-weight: 700;
    color: ${({ error }) => (error ? "var(--red)" : "var(--green)")};
`
export default Response
