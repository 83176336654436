import React, { useState, useContext } from "react"
import { useQuery } from "react-query"
import { UserContext } from "../../context/UserContext"
import axios from "../../../utils/axios"
import styled from "styled-components"
import { useProductsQuery } from "./useProductsQuery"
import { GridContainer } from "../../styled/GridContainer"
import { H2 } from "../../styled/headers"
import { routes } from "@routes"
import { COMPLETED_ORDERS } from "@queries"
import { isBrowser } from "../../../utils/isBrowser"
import Spinner from "../../common/Spinner"
import TabsMenu from "../../common/TabsMenu"
import { formatDate } from "../../../utils/formatDate"
import Headline from "../../common/Headline"
import TrainingCard from "../trainings-list/training-card/TrainingCard"
import AfterSurvey from "./survey/AfterSurvey"
import { useUserIsLogged } from "../../../hooks/useUserIsLogged"
import { Content } from "../../styled/Content"
import Filter from "./Filter"
import { breakpoints } from "@breakpoints"

const TabContent = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
    margin: 30px 0;
`

const CardWrapper = styled.div`
    max-width: 500px;
    margin-top: 30px;
`

const MenuWrapper = styled.div`
    position: relative;
    padding-bottom: 30px;
    margin-bottom: -30px;

    &:after {
        content: "";
        position: absolute;
        right: -20px;
        top: -100px;
        width: 100vw;
        height: calc(100% + 100px);
        background-color: var(--medium-gray);
        z-index: -1;
    }

    ${breakpoints.lg} {
        margin-bottom: -50px;
    }
`

const KnowledgeBase = ({
    acf: {
        header,
        subheader,
        sortByAllText,
        sortByFreeText,
        sortByPaidText,
        sortText,
    },
}: props) => {
    useUserIsLogged()
    const filterSettings = [
        {
            title: sortByAllText,
            type: "any",
        },
        {
            title: sortByFreeText,
            type: "free-training",
        },
        {
            title: sortByPaidText,
            type: "paid-training",
        },
    ]

    const [filter, setFilter] = useState(filterSettings[0])
    const { userData } = useContext<any>(UserContext)
    const activeItem =
        isBrowser() && window.history.state
            ? window.history.state.title
            : undefined

    const { isLoading, data } = useQuery(
        COMPLETED_ORDERS,
        () =>
            axios.get(
                `orders?customer=${userData.id}&status=completed&per_page=99`
            ),
        {
            enabled: userData ? true : false,
        }
    )
    const products = useProductsQuery()
    const [active, setActive] = useState(activeItem ?? products[0].name)
    const availableTrainings = () => {
        let items: availableTrainings = {
            names: [],
        }
        data?.data.forEach(({ id, line_items }: orderData) => {
            line_items.forEach(({ name, id: trainingId, meta_data }) => {
                let surveyAfterCompleted = false
                meta_data.forEach(({ key }) => {
                    if (key === "surveyAfter") {
                        surveyAfterCompleted = true
                    }
                })
                items.names.push(name)
                // assign orderID and traningID to each training
                items[name] = {
                    id: trainingId,
                    orderId: id,
                    surveyAfterCompleted: surveyAfterCompleted,
                }
            })
        })
        return items
    }
    const menuItems = products
        .map(({ id, name, acf: { type } }) => ({
            id,
            title: name,
            type,
            disabled:
                type === "free-training"
                    ? false
                    : !availableTrainings().names.includes(name),
        }))
        .filter(item => item.type === filter.type || filter.type === "any")

    if (!userData) {
        return null
    }
    return (
        <section>
            <GridContainer>
                <div className="grid grid-cols-12">
                    <MenuWrapper className="col-span-12 md:col-span-4">
                        <Headline header={header} subheader={subheader} />
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                <Filter
                                    header={sortText}
                                    filter={filter}
                                    setFilter={setFilter}
                                    options={filterSettings}
                                />
                                <TabsMenu
                                    active={active}
                                    setActive={setActive}
                                    items={menuItems}
                                />
                            </>
                        )}
                    </MenuWrapper>
                    {data &&
                        products?.map(
                            ({
                                id,
                                name,
                                price,
                                slug,
                                shortDescription,
                                acf: {
                                    type,
                                    dateStart,
                                    dateEnd,
                                    place,
                                    image,
                                    knowledgeBaseDisabledText,
                                    knowledgeBaseContent,
                                },
                            }) => {
                                let trainingId = availableTrainings()[name]
                                    ? availableTrainings()[name].id
                                    : undefined
                                let orderId = availableTrainings()[name]
                                    ? availableTrainings()[name].orderId
                                    : undefined
                                let trainingEnded = false
                                let surveyAfterCompleted = availableTrainings()[
                                    name
                                ]
                                    ? availableTrainings()[name]
                                          .surveyAfterCompleted
                                    : undefined
                                if (dateEnd) {
                                    trainingEnded =
                                        new Date() >
                                        new Date(
                                            formatDate(dateEnd).dateToCompare
                                        )
                                }

                                return (
                                    <TabContent
                                        active={name === active}
                                        className="col-span-12 md:col-span-7 md:col-start-6"
                                        key={name}
                                    >
                                        {type === "free-training" ||
                                        availableTrainings().names.includes(
                                            name
                                        ) ? (
                                            <>
                                                <H2
                                                    dangerouslySetInnerHTML={{
                                                        __html: name,
                                                    }}
                                                />

                                                <Content
                                                    key={id}
                                                    dangerouslySetInnerHTML={{
                                                        __html: knowledgeBaseContent,
                                                    }}
                                                />
                                                {type !== "free-training" &&
                                                    orderId &&
                                                    trainingEnded && (
                                                        <AfterSurvey
                                                            trainingId={
                                                                trainingId
                                                            }
                                                            orderId={orderId}
                                                            completed={
                                                                surveyAfterCompleted
                                                            }
                                                        />
                                                    )}
                                            </>
                                        ) : (
                                            <>
                                                <H2
                                                    dangerouslySetInnerHTML={{
                                                        __html: name,
                                                    }}
                                                />
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: knowledgeBaseDisabledText,
                                                    }}
                                                />
                                                {type !== "free-training" && (
                                                    <CardWrapper>
                                                        <TrainingCard
                                                            dateStart={
                                                                dateStart &&
                                                                formatDate(
                                                                    dateStart
                                                                ).dateToDisplay
                                                            }
                                                            dateEnd={dateEnd}
                                                            title={name}
                                                            place={place}
                                                            price={price}
                                                            href={`${routes.trainingsAndEvents}/${slug}`}
                                                            desc={
                                                                shortDescription
                                                            }
                                                            image={image}
                                                            type="paid-training"
                                                        />
                                                    </CardWrapper>
                                                )}
                                            </>
                                        )}
                                    </TabContent>
                                )
                            }
                        )}
                </div>
            </GridContainer>
        </section>
    )
}

export default KnowledgeBase

interface props {
    acf: {
        header: string
        subheader: string
        sortByAllText: string
        sortByFreeText: string
        sortByPaidText: string
        sortText: string
    }
}

interface orderData {
    line_items: Array<{ id: number; name: string; meta_data: Array<any> }>
    id: number
}

type availableTrainings = {
    names: Array<string>
    training?: { id: number; orderId: number }
}
