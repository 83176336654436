import React, { useState } from "react"
import { Link } from "gatsby"
import { useMutation, useQueryClient } from "react-query"
import { useBasicQuery } from "@useBasicQuery"
import { USER_ORDER } from "../../../../consts/queries"
import {
    setLocalStorage,
    removeLocalStorage,
} from "../../../../utils/localStorage"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import axios from "../../../../utils/axios"
import { H4 } from "../../../styled/headers"
import Spinner from "../../../common/Spinner"
import RemoveOrder from "@icons/close-2.svg"
import { gatsbyImage, metaData } from "@types"
import { breakpoints } from "@breakpoints"

const StyledProduct = styled(props => <Link {...props} />)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 40px 20px 0px;
    text-align: left;

    ${breakpoints.md} {
        margin: 0;
    }

    .gatsby-image-wrapper {
        width: 200px;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 20px;

        ${breakpoints.sm} {
            width: 400px;
        }

        ${breakpoints.md} {
            width: 200px;
        }
    }
`

const StyledSpinner = styled.div<{ mobile?: boolean }>`
    margin-left: auto;
    display: ${({ mobile }) => (mobile ? "flex" : "none")};
    justify-content: flex-end;

    ${breakpoints.md} {
        display: ${({ mobile }) => (mobile ? "none" : "inline")};
    }
`

const StyledDate = styled.div`
    font-size: var(--base-font);
    font-weight: 700;
    color: var(--light-blue);
    margin: 10px 0 5px;
`

const StyledPlace = styled.div`
    font-size: var(--small-font);
    font-weight: 600;
    color: var(--gray);
    text-transform: uppercase;
    letter-spacing: 1;
`

const StyledRemoveOrder = styled.div<{ mobile?: boolean; disabled: boolean }>`
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    display: ${({ mobile }) => (mobile ? "block" : "none")};
    margin-bottom: ${({ mobile }) => (mobile ? "-50px" : "0")};
    svg {
        margin-left: auto;
    }

    ${breakpoints.md} {
        display: ${({ mobile }) => (mobile ? "none" : "inline")};
    }
`

const StyledWrapper = styled.div`
    padding: 30px;
    align-items: center;
    border-bottom: 1px solid var(--light-gray);
    text-align: right;

    ${breakpoints.md} {
        text-align: left;
    }

    &:last-child {
        border-bottom: transparent;
    }
`

const StyledImage = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${breakpoints.md} {
        width: auto;
    }
`

const StyledData = styled.div`
    width: 100%;

    ${breakpoints.lg} {
        width: auto;
    }
`

const MobileText = styled.span`
    ${breakpoints.md} {
        display: none;
    }
`

const PriceContainer = styled.span<{ discount: boolean }>`
    color: ${({ discount }) => (discount ? "var(--green)" : "inital")};
`

const Orders = ({
    orderId,
    products,
    deleteOrderError,
    amountText,
    sumText,
}: props) => {
    const [deleting, setDeleting] = useState<false | number>(false)
    const [error, setError] = useState<false | string>(false)
    const queryClient = useQueryClient()
    const {
        general: {
            acf: { price: priceText },
        },
    } = useBasicQuery()

    const deleteProduct = (idToDelete: number) => {
        return axios.put(`orders/${orderId}`, {
            line_items: products?.map(product => {
                return {
                    id: product.id,
                    quantity: product.id === idToDelete ? 0 : product.amount,
                    meta_data: product.meta_data.map(data => {
                        return {
                            key: data.key,
                            value: data.value,
                        }
                    }),
                }
            }),
        })
    }

    const mutation = useMutation(
        (idToDelete: number) => deleteProduct(idToDelete),
        {
            onMutate: id => {
                setDeleting(id)
            },
            onSuccess: data => {
                products?.length === 1
                    ? removeLocalStorage("orders")
                    : setLocalStorage("orders", JSON.stringify(data.data))
                queryClient.invalidateQueries(USER_ORDER)
            },
            onError: () => {
                setDeleting(false)
                setError("Produkt nie został usunięty, spróbuj za chwilę")
            },
        }
    )

    return (
        <>
            {products?.map(product => (
                <StyledWrapper
                    key={product.id}
                    className="md:grid md:grid-cols-8"
                >
                    {deleting === product.id ? (
                        <StyledSpinner mobile>
                            <Spinner />
                        </StyledSpinner>
                    ) : (
                        <>
                            <StyledRemoveOrder
                                mobile
                                disabled={
                                    deleting !== false &&
                                    deleting !== product.id
                                }
                                onClick={() => {
                                    deleting !== false &&
                                    deleting !== product.id
                                        ? false
                                        : mutation.mutate(product.id)
                                }}
                            >
                                <RemoveOrder />
                            </StyledRemoveOrder>
                        </>
                    )}
                    <StyledProduct to={product.slug} className="col-span-3">
                        <StyledImage>
                            <GatsbyImage
                                alt={product.name}
                                image={
                                    product.image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </StyledImage>

                        <StyledData>
                            <H4
                                small
                                dangerouslySetInnerHTML={{
                                    __html: product.name,
                                }}
                            />
                            {product.date && (
                                <StyledDate
                                    dangerouslySetInnerHTML={{
                                        __html: product.date,
                                    }}
                                />
                            )}
                            {product.place && (
                                <StyledPlace
                                    dangerouslySetInnerHTML={{
                                        __html: product.place,
                                    }}
                                />
                            )}
                        </StyledData>
                    </StyledProduct>

                    <H4 small dangerouslySetInnerHTML={{ __html: "" }} />
                    <H4 small>
                        <MobileText
                            dangerouslySetInnerHTML={{
                                __html: `${priceText} `,
                            }}
                        />
                        <PriceContainer discount={product.priceReduced}>
                            {product.price} {product.currency}
                        </PriceContainer>
                    </H4>
                    <H4 small>
                        <MobileText
                            dangerouslySetInnerHTML={{
                                __html: `${amountText} `,
                            }}
                        />
                        {product.amount}
                    </H4>

                    <H4 small>
                        <MobileText
                            dangerouslySetInnerHTML={{
                                __html: `${sumText} `,
                            }}
                        />
                        <PriceContainer discount={product.priceReduced}>
                            {product.totalPrice} {product.currency}
                        </PriceContainer>
                    </H4>
                    {deleting === product.id ? (
                        <StyledSpinner>
                            <Spinner />
                        </StyledSpinner>
                    ) : (
                        <>
                            <StyledRemoveOrder
                                disabled={
                                    deleting !== false &&
                                    deleting !== product.id
                                }
                                onClick={() => {
                                    deleting !== false &&
                                    deleting !== product.id
                                        ? false
                                        : mutation.mutate(product.id)
                                }}
                            >
                                <RemoveOrder />
                            </StyledRemoveOrder>
                        </>
                    )}
                    {error && (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: deleteOrderError,
                            }}
                        />
                    )}
                </StyledWrapper>
            ))}
        </>
    )
}

export default Orders

interface props {
    deleteOrderError: string
    orderId: number
    amountText: string
    sumText: string
    products?: Array<{
        slug: string
        id: number
        image: gatsbyImage
        name: string
        amount: string
        price: string
        priceReduced: boolean
        currency: string
        totalPrice: string
        place?: string
        date?: string
        meta_data: Array<metaData>
    }>
}
