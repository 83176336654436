import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"

const HeaderWrapper = styled.span`
    display: flex;
    color: var(--dark-blue);
    font-size: var(--base-font);

    span {
        font-weight: 700;
    }

    ${breakpoints.md} {
        flex-direction: column;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${breakpoints.md} {
        flex-direction: row;
        justify-content: space-between;
    }
`

const TopBar = ({
    orderNumberText,
    orderDateText,
    deliveryDateText,
    deliveryDate,
    totalCostText,
    total,
    paymentStatusText,
    id,
    datePaid,
}: props) => {
    const settings = [
        {
            text: orderNumberText,
            value: id,
        },
        {
            text: orderDateText,
            value: datePaid ? datePaid.slice(0, 10) : "-",
        },
        {
            text: deliveryDateText,
            value: deliveryDate
                ? `${deliveryDate.slice(0, 4)}-${deliveryDate.slice(
                      4,
                      6
                  )}-${deliveryDate.slice(6, 8)}`
                : "-",
        },
        {
            text: totalCostText,
            value: `${total}zł`,
        },
        {
            text: paymentStatusText,
            value: "Opłacono",
        },
    ]
    return (
        <Wrapper>
            {settings.map(({ text, value }) => (
                <HeaderWrapper>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                    {value}
                </HeaderWrapper>
            ))}
        </Wrapper>
    )
}

export default TopBar

interface props {
    id: number
    datePaid: string
    orderNumberText: string
    orderDateText: string
    deliveryDateText: string
    deliveryDate?: string
    totalCostText: string
    total: string
    paymentStatusText: string
}
