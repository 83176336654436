import React from "react"
import styled from "styled-components"

const IntroText = styled.div`
    margin-bottom: 50px;
    font-size: var(--big-font);
    color: var(--medium-blue);
    font-weight: 700;
`

const CompletedText = styled.div`
    color: var(--green);
    font-weight: 700;
`

const Intro = ({ completed, introText, completedText }: props) => {
    return (
        <>
            <IntroText dangerouslySetInnerHTML={{ __html: introText }} />
            {completed && (
                <CompletedText
                    dangerouslySetInnerHTML={{ __html: completedText }}
                />
            )}
        </>
    )
}

export default Intro

interface props {
    completed: boolean
    introText: string
    completedText: string
}
