import React from "react"
import styled from "styled-components"
import Headline from "../../../common/Headline"
import Card from "../account/Card"
import { H5 } from "../../../styled/headers"

const Wrappper = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
`

const Trainings = ({
    active,
    goToCourseText,
    readMoreText,
    noPurchasedText,
    data: { content, header, subheader },
    trainings,
}: props) => {
    return (
        <Wrappper active={active}>
            <Headline header={header} subheader={subheader} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {trainings.length > 0 ? (
                trainings.map(
                    ({
                        name,
                        date,
                        place,
                        slug,
                        ended,
                        surveyAfter,
                    }: training) => (
                        <Card
                            goToCourseText={goToCourseText}
                            readMoreText={readMoreText}
                            date={date}
                            place={place}
                            slug={slug}
                            name={name}
                            ended={ended}
                            surveyAfter={surveyAfter}
                        />
                    )
                )
            ) : (
                <H5
                    style={{ color: "var(--red)", marginTop: "20px" }}
                    dangerouslySetInnerHTML={{ __html: noPurchasedText }}
                />
            )}
        </Wrappper>
    )
}

export default Trainings

interface props {
    active: boolean
    goToCourseText: string
    readMoreText: string
    noPurchasedText: string
    trainings: [] | Array<training>
    data: {
        content: string
        header: string
        subheader: string
    }
}

interface training {
    name: string
    date: string
    place: string
    slug: string
    ended: boolean
    surveyAfter: boolean
}
