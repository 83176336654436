import React from "react"
import styled from "styled-components"
import { H2 } from "../../../styled/headers"

const StyledTelephone = styled.a`
    display: block;
    margin: 20px 0 30px;
`

const Telephone = ({ number, text }: props) => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <StyledTelephone href={`tel:${number}`}>
                <H2 dangerouslySetInnerHTML={{ __html: number }} />
            </StyledTelephone>
        </div>
    )
}

export default Telephone

interface props {
    number: string
    text: string
}
