import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"
import { H4 } from "../../styled/headers"
import { breakpoints } from "@breakpoints"

const StyledWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 30px;
`

const StyleMethodWrapper = styled.div<{ active?: boolean }>`
    cursor: pointer;
    height: 100px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    border: ${({ active }) =>
        active ? "2px solid var(--light-blue)" : "2px solid transparent"};

    ${breakpoints.sm} {
        width: auto;
    }

    .gatsby-image-wrapper {
        width: 100px;
        margin: 10px;
    }
`

const PaymentMethods = ({ header, methods, payment, setPayment }: props) => {
    return (
        <div>
            <H4 dangerouslySetInnerHTML={{ __html: header }} />
            <StyledWrapper data-cy="BanksGrid">
                {methods &&
                    methods.map(({ image, id }) => (
                        <StyleMethodWrapper
                            key={id}
                            active={payment === id}
                            onClick={() => setPayment(id)}
                        >
                            <GatsbyImage
                                alt=""
                                image={
                                    image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </StyleMethodWrapper>
                    ))}
            </StyledWrapper>
        </div>
    )
}

export default PaymentMethods

interface props {
    header: string
    methods: Array<{
        id: number
        image: gatsbyImage
    }>
    payment: false | number
    setPayment: (payment: number) => void
}
