import React from "react"
import { usefiltrTrainings } from "./useFiltrTrainings"
import Presentation from "./Presentation"
import LazyLoad from "react-lazyload"

const TrainingsList = ({
    acf: {
        freeTrainingsOnly,
        upcoming,
        header,
        subheader,
        buttonText,
        buttonLink,
    },
}: props) => {
    const trainings = usefiltrTrainings(upcoming, freeTrainingsOnly)
    const buttonSettings =
        upcoming && buttonText && buttonLink && buttonLink.slug
            ? { href: `/${buttonLink.slug}`, text: buttonText }
            : false

    return (
        <LazyLoad>
            <Presentation
                header={header}
                subheader={subheader}
                buttonSettings={buttonSettings}
                trainings={trainings}
            />
        </LazyLoad>
    )
}

export default TrainingsList

interface props {
    acf: {
        freeTrainingsOnly: boolean
        upcoming: boolean
        header: string
        subheader: string
        buttonText: string
        buttonLink: {
            slug: string
        }
    }
}
