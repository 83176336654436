import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { H4 } from "../../styled/headers"
import RadioButton from "../../common/form/RadioButton"
import Textarea from "../../common/form/Textarea"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"

const StyledWrapperOuter = styled.div`
    margin-bottom: 30px;
`

const StyledBox = styled.div`
    width: 250px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;

    ${breakpoints.lg} {
        width: 45%;
        margin-bottom: 0;
    }

    .gatsby-image-wrapper {
        min-width: 80px;
    }
    img {
        height: auto;
    }
`

const StyledTextareWrapper = styled.div`
    margin-top: 30px;
    height: 150px;
    display: block;
`

const StyledRadioContainer = styled.div`
    display: flex;
`

const StyledWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;

    ${breakpoints.lg} {
        flex-direction: row;
    }
`

const StyledPrice = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    min-width: 50px;
    text-align: center;
`

const DeliveryAddress = styled.div`
    margin-top: 20px;
`

const DeliveryMethod = ({
    setOrderMethod,
    setOrderNotes,
    orderMethod,
    orderNotes,
    header,
    logo,
    price,
    notesPlaceholder,
}: props) => {
    return (
        <StyledWrapperOuter>
            <H4 dangerouslySetInnerHTML={{ __html: header }} />
            <StyledWrapper>
                <StyledBox
                    onClick={() =>
                        setOrderMethod({
                            method: "DPD",
                            price: price,
                        })
                    }
                >
                    <GatsbyImage
                        alt="Dostawa: DPD"
                        image={logo.localFile.childImageSharp.gatsbyImageData}
                    />
                    <StyledRadioContainer data-cy="CourierCheckBox">
                        <StyledPrice>
                            <H4
                                small
                                dangerouslySetInnerHTML={{
                                    __html: `${price} zł`,
                                }}
                            />
                        </StyledPrice>

                        <RadioButton
                            checked={orderMethod.method === "DPD"}
                            name="delivery"
                            value="dpd"
                            id="dpd"
                            readOnly
                        />
                    </StyledRadioContainer>
                </StyledBox>
                <StyledBox
                    onClick={() =>
                        setOrderMethod({
                            method: "odbior-osobisty",
                            price: 0.0,
                        })
                    }
                >
                    <H4
                        small
                        dangerouslySetInnerHTML={{ __html: "Odbiór osobisty" }}
                    />
                    <StyledRadioContainer data-cy="selfPickupCheckbox">
                        <StyledPrice>
                            <H4
                                small
                                dangerouslySetInnerHTML={{ __html: "0 zł" }}
                            />
                        </StyledPrice>

                        <RadioButton
                            checked={orderMethod.method === "odbior-osobisty"}
                            name="delivery"
                            value="odbior-osobisty"
                            id="odbior-osobisty"
                            readOnly
                        />
                    </StyledRadioContainer>
                </StyledBox>
            </StyledWrapper>
            {orderMethod.method === "odbior-osobisty" && (
                <DeliveryAddress>
                    Biuro Fundacji GOPR 51-162 Wrocław <br />
                    ul. Jana Długosza 59-75 <br />
                    klatka C, piętro II
                </DeliveryAddress>
            )}
            <StyledTextareWrapper>
                <Textarea
                    placeholder={notesPlaceholder}
                    value={orderNotes ? orderNotes : ""}
                    onChange={e => setOrderNotes(e)}
                />
            </StyledTextareWrapper>
        </StyledWrapperOuter>
    )
}

export default DeliveryMethod

interface props {
    setOrderMethod: ({
        method,
        price,
    }: {
        method: string
        price: number
    }) => void
    setOrderNotes: (notes: string) => void
    orderMethod: {
        method: string
        price: number
    }
    orderNotes: string | false
    header: string
    logo: gatsbyImage
    price: number
    notesPlaceholder: string
}
