import React from "react"
import styled from "styled-components"
import Radio from "../../../common/form/RadioButton"
import Textarea from "../../../common/form/Textarea"

const Wrapper = styled.div`
    margin: 30px 0;
`

const Text = styled.div<{ error: boolean }>`
    margin-bottom: 20px;
    font-weight: 700;
    color: ${({ error }) => (error ? "var(--red)" : "initial")};
`

const RadioWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`

const Question = ({
    setSurveyParts,
    surveyParts,
    question,
    type,
    index,
}: props) => {
    const setAnswer = (radio: number) => {
        setSurveyParts((prev: surveyParts) =>
            prev.map(({ question, answer, error }, i) => {
                if (i !== index) {
                    return {
                        question,
                        answer,
                        error,
                    }
                }
                return {
                    question,
                    answer: radio,
                    error: false,
                }
            })
        )
    }

    const radioButtonAnswers = [1, 2, 3, 4, 5]
    return (
        <Wrapper>
            <Text
                error={surveyParts[index].error}
                dangerouslySetInnerHTML={{ __html: question }}
            />
            {type === "radio" ? (
                <RadioWrapper>
                    {radioButtonAnswers.map(answer => (
                        <Radio
                            key={`${question}-${answer}`}
                            onClick={() => setAnswer(answer)}
                            id={`${question}-${answer}`}
                            name={question}
                            label={`${answer}`}
                            value={answer}
                        />
                    ))}
                </RadioWrapper>
            ) : (
                <Textarea
                    placeholder="Twoja odpowiedź..."
                    onChange={setAnswer}
                    value={surveyParts[index].answer}
                />
            )}
        </Wrapper>
    )
}

export default Question

type surveyParts = Array<{ answer: string; question: string; error: boolean }>

interface props {
    index: number
    surveyParts: surveyParts
    setSurveyParts: any
    question: string
    type: "radio" | "descriptive"
}
