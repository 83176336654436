import React from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "@breakpoints"
import { H4 } from "../../../styled/headers"
import { Link, navigate } from "gatsby"
import Arrow from "@icons/bronze-arrow.svg"
import RedArrow from "@icons/red-arrow.svg"
import { routes } from "@routes"
import Warning from "@icons/warning.svg"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;

    ${breakpoints.md} {
        justify-content: space-between;
        align-items: flex-start;
        padding: 40px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;

    ${breakpoints.md} {
        margin-bottom: 40px;
    }
`

const Date = styled.div`
    font-weight: 700;
    color: var(--light-blue);
    font-size: var(--small-font);
    margin-bottom: 10px;

    ${breakpoints.md} {
        font-size: var(--medium-font);
    }
`

const Place = styled.div`
    color: var(--gray);
    font-size: var(--small-font);
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
`
const linkStyles = css`
    display: flex;
    align-items: center;
    font-weight: 700;
    color: var(--bronze);
    font-size: var(--small-font);

    ${breakpoints.lg} {
        font-size: var(--medium-font);
    }

    svg {
        margin-left: 10px;
        width: 10px;
    }
`
const StyledLink = styled(props => <Link {...props} />)`
    ${linkStyles}
`

const KnowledgeBaseLink = styled.div`
    ${linkStyles}
    margin-left: auto;
    margin-top: 20px;
    cursor: pointer;

    ${breakpoints.md} {
        margin-top: 0;
    }
`

const SurveyInfo = styled.div`
    display: flex;
    align-items: center;
    color: var(--red);
    font-weight: 700;

    svg {
        margin-right: 10px;
        width: 20px;
    }
`

const ArrowContainer = styled.div`
    svg {
        margin-left: 10px;
        margin-right: 0;
        width: 10px;
    }
`

const KnowledgeBaseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    font-size: var(--small-font);
    margin-top: 20px;
    cursor: pointer;

    ${breakpoints.md} {
        flex-direction: row;
    }

    ${breakpoints.lg} {
        font-size: var(--medium-font);
    }
`

const Card = ({
    name,
    date,
    ended,
    place,
    slug,
    readMoreText,
    goToCourseText,
    surveyAfter,
}: props) => {
    return (
        <Wrapper>
            <Content>
                <Date dangerouslySetInnerHTML={{ __html: date }} />
                <H4 dangerouslySetInnerHTML={{ __html: name }} />
                <Place dangerouslySetInnerHTML={{ __html: place }} />
                <StyledLink to={slug}>
                    {readMoreText}
                    <Arrow />
                </StyledLink>
            </Content>
            <KnowledgeBaseContainer
                onClick={() => {
                    navigate(routes.knowledgeBase, {
                        state: { title: name },
                    })
                }}
            >
                {ended && !surveyAfter && (
                    <SurveyInfo>
                        <Warning />
                        Prosimy o wypełnienie ankiety po zakończonym kursie
                        <ArrowContainer>
                            <RedArrow />
                        </ArrowContainer>
                    </SurveyInfo>
                )}

                <KnowledgeBaseLink>
                    {goToCourseText}
                    <Arrow />
                </KnowledgeBaseLink>
            </KnowledgeBaseContainer>
        </Wrapper>
    )
}

export default Card

interface props {
    goToCourseText: string
    readMoreText: string
    name: string
    date: string
    place: string
    slug: string
    ended: boolean
    surveyAfter: boolean
}
