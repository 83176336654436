import React from "react"
import { useBasicQuery } from "@useBasicQuery"
import { useQuery } from "./useQuery"
import Gadget from "./Gadget"
import Headline from "../../common/Headline"
import { gatsbyImage } from "@types"
import { GridContainer } from "../../styled/GridContainer"

const GadgetsList = ({ acf: { subheader, header } }: props) => {
    const {
        general: {
            acf: { buyNow, price: priceLabel },
        },
    } = useBasicQuery()
    const products = useQuery()
    return (
        <GridContainer>
            <Headline subheader={subheader} header={header} />
            <div
                data-cy="gadgetsGrid"
                className="grid sm:grid-cols-2 md:grid-cols-3 gap-5"
            >
                {products &&
                    products.map(
                        ({
                            id,
                            name,
                            price,
                            slug,
                            shortDescription,
                            acf: { gadgetImage },
                        }: singleGadget) => (
                            <Gadget
                                key={id}
                                name={name}
                                image={gadgetImage}
                                price={{ label: priceLabel, value: price }}
                                slug={slug}
                                buttonText={buyNow}
                                desc={shortDescription}
                            />
                        )
                    )}
            </div>
        </GridContainer>
    )
}

export default GadgetsList

interface props {
    acf: {
        header: string
        subheader: string
    }
}

interface singleGadget {
    id: string
    name: string
    price: string
    slug: string
    shortDescription: string
    acf: {
        gadgetImage: gatsbyImage
    }
}
