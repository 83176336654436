import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import md5 from "md5"
import { useQuery, useQueryClient } from "react-query"
import { USER_ORDER } from "../../../consts/queries"

import { UserContext } from "../../context/UserContext"
import { GridContainer } from "../../styled/GridContainer"
import { setLocalStorage } from "../../../utils/localStorage"
import Headline from "../../common/Headline"
import List from "./list/List"
import axios from "../../../utils/axios"
import DeliveryMethod from "./DeliveryMethod"
import AddressData from "./AddressData"
import PaymentMethods from "./PaymentMethods"
import ContactData from "./ContactData"
import Coupon from "./Coupon"
import Total from "./Total"
import { useBasicQuery } from "@useBasicQuery"
import { gatsbyImage } from "@types"
import { useUserIsLogged } from "../../../hooks/useUserIsLogged"
import { routes } from "@routes"

const Cart = ({
    acf: {
        addressDataHeader,
        amountText,
        contactDataHeader,
        couponButtonText,
        couponHeader,
        couponPlaceholder,
        couponSuccessText,
        couponErrorText,
        deleteOrderError,
        deliveryLogo,
        deliveryMethodText,
        deliveryNotesPlaceholder,
        deliveryPrice,
        header,
        paymentButtonText,
        paymentMethodsHeader,
        paymentMethods,
        productText,
        sizeText,
        subheader,
        sumText,
        totalCostText,
    },
}: props) => {
    useUserIsLogged()
    const queryClient = useQueryClient()
    const { userData, setUserData, setUserOrder } = useContext<any>(UserContext)
    const [coupon, setCoupon] = useState("")
    const [couponIsLoading, setCouponIsLoading] = useState(false)
    const [productsCost, setProductsCost] = useState(0)
    const [totalCost, setTotalCost] = useState("00.00")
    const [fieldsError, setFieldsError] = useState(false)
    const [gadgetInCart, setGadgetInCart] = useState(false)
    const [finalizingPayment, setFinalizingPayment] = useState(false)

    const [couponResponse, setCouponResponse] = useState<{
        type: "" | "success" | "error"
        message: string
    }>({
        type: "",
        message: "",
    })
    const [orderNotes, setOrderNotes] = useState<false | string>(false)
    const [payment, setPayment] = useState<false | number>(paymentMethods[3].id)
    const [orderMethod, setOrderMethod] = useState({
        method: "DPD",
        price: deliveryPrice,
    })
    const [address, setAddress] = useState({
        message: "",
        error: false,
    })
    const [postalCode, setPostalCode] = useState({
        message: "",
        error: false,
    })
    const [city, setCity] = useState({
        message: "",
        error: false,
    })
    const [firm, setFirm] = useState("")
    const [nip, setNip] = useState("")
    const [name, setName] = useState({
        message: "",
        error: false,
    })
    const [surname, setSurname] = useState({
        message: "",
        error: false,
    })
    const [email, setEmail] = useState({
        message: "",
        error: false,
    })
    const [telephone, setTelephone] = useState("")

    const {
        forms: {
            acf: {
                emailLabel,
                emailPlaceholder,
                nameLabel,
                namePlaceholder,
                surnameLabel,
                surnamePlaceholder,
                telephoneLabel,
                telephonePlaceholder,
                addressLabel,
                addressPlaceholder,
                cityLabel,
                cityPlaceholder,
                firmLabel,
                firmPlaceholder,
                nipLabel,
                nipPlaceholder,
                postalLabel,
                postalPlaceholder,
            },
        },
    } = useBasicQuery()

    const {
        isLoading,
        isFetching,
        data: orderData,
    } = useQuery(
        USER_ORDER,
        () =>
            axios.get(
                `orders?customer=${userData.id}&status=pending&per_page=99`
            ),
        {
            enabled: userData ? true : false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            onSuccess: data => {
                setUserOrder(data?.data[0])
            },
        }
    )

    useEffect(() => {
        if (orderData) {
            setProductsCost(
                orderData.data[0] ? parseFloat(orderData.data[0].total) : 0
            )

            const gadgetIsInCart: Array<boolean> = []

            if (!orderData.data[0]) return

            orderData.data[0].line_items.forEach((order: any) => {
                gadgetIsInCart.push(isGadget(order.meta_data))
            })
            if (gadgetIsInCart.includes(true)) {
                setGadgetInCart(true)
            } else {
                setGadgetInCart(false)
            }
            isFreeDelivery()
        }
    }, [orderData])

    useEffect(() => {
        if (userData) {
            setAddress({
                message: userData.billing.address_1,
                error: false,
            })
            setPostalCode({
                message: userData.billing.postcode,
                error: false,
            })
            setFirm(userData.billing.company)
            setNip(userData.shipping.company)
            setCity({
                message: userData.billing.city,
                error: false,
            })
            setName({
                message: userData.first_name,
                error: false,
            })
            setSurname({
                message: userData.last_name,
                error: false,
            })
            setEmail({
                message: userData.email,
                error: false,
            })
            setTelephone(userData.billing.phone)
        }
        isFreeDelivery()
    }, [])

    useEffect(() => {
        setTotalCost((productsCost + orderMethod.price).toFixed(2))
        // add delivery price only when gadget is in cart
        const priceToAdd = gadgetInCart ? orderMethod.price : 0
        setTotalCost((productsCost + priceToAdd).toFixed(2))
    }, [productsCost, orderMethod])

    const isGadget = (
        meta_data: Array<{ key: string; value: any }>
    ): boolean => {
        let isGadget = false
        meta_data.forEach(({ key, value }) => {
            if (key === "type" && value === "gadget") {
                isGadget = true
            }
        })
        return isGadget
    }

    if (!userData) {
        return null
    }

    const applyCoupon = () => {
        setCouponIsLoading(true)
        setCouponResponse({
            type: "error",
            message: "",
        })
        if (orderData) {
            const usedCoupons = orderData.data[0].coupon_lines.map(
                ({ code }: { code: string }) => {
                    return { code }
                }
            )

            if (
                usedCoupons
                    .map(({ code }: { code: string }) => code)
                    .includes(coupon)
            ) {
                setCouponResponse({
                    type: "error",
                    message: "Kupon został już wykorzystany",
                })
                setCouponIsLoading(false)
                return
            }
            axios
                .put(`/orders/${orderData.data[0].id}`, {
                    coupon_lines: [
                        ...orderData.data[0].coupon_lines.map(
                            ({ code }: { code: string }) => {
                                return { code }
                            }
                        ),
                        {
                            code: coupon,
                        },
                    ],
                })
                .then(_ => {
                    queryClient.invalidateQueries(USER_ORDER)
                    setCouponIsLoading(false)
                    setCouponResponse({
                        type: "success",
                        message: couponSuccessText,
                    })
                })
                .catch(error => {
                    setCouponIsLoading(false)
                    setCouponResponse({
                        type: "error",
                        message: couponErrorText,
                    })
                    console.error(error)
                })
        }
    }

    const fieldsAreFilled = (): boolean => {
        const postalCodeValid = () => {
            // prettier-ignore
            const polandPostalCode = new RegExp("^\\d{2}[- ]{0,1}\\d{3}$")
            const valid = polandPostalCode.test(postalCode.message)
            if (!valid) {
                setPostalCode(prev => ({
                    message: prev.message,
                    error: true,
                }))
                return false
            }
            return true
        }

        const notEmpty = (
            value: string,
            callback: ({
                message,
                error,
            }: {
                message: string
                error: boolean
            }) => void
        ) => {
            if (value === "") {
                callback({
                    message: "",
                    error: true,
                })
                return false
            }
            return true
        }
        if (gadgetInCart) {
            notEmpty(address.message, setAddress)
            postalCodeValid()
            notEmpty(city.message, setCity)
        }

        notEmpty(name.message, setName)
        notEmpty(surname.message, setSurname)
        notEmpty(email.message, setEmail)

        return (
            (notEmpty(address.message, setAddress) || !gadgetInCart) &&
            (postalCodeValid() || !gadgetInCart) &&
            (notEmpty(city.message, setCity) || !gadgetInCart) &&
            notEmpty(name.message, setName) &&
            notEmpty(surname.message, setSurname) &&
            notEmpty(email.message, setEmail)
        )
    }

    const isFreeDelivery = () => {
        let freeDelivery: any = []
        const items =
            orderData && orderData.data[0]
                ? orderData.data[0].line_items
                : false

        if (!items) return false
        items.map((item: any) => {
            item.meta_data.forEach(({ key, value }: any) => {
                if (key === "free-delivery") {
                    if (value === "true") {
                        freeDelivery.push(true)
                    } else {
                        freeDelivery.push(false)
                    }
                }
            })
        })

        if (!freeDelivery.includes(false)) {
            setOrderMethod({
                method: "free-delivery",
                price: 0.0,
            })
        } else {
            setOrderMethod({
                method: "DPD",
                price: deliveryPrice,
            })
        }
    }

    const finalizePayment = (): void => {
        setFinalizingPayment(true)
        setFieldsError(false)

        if (totalCost === "0.00") {
            axios
                .put(`customers/${userData.id}`, {
                    meta_data: [
                        {
                            key: "order",
                            value: {
                                id: orderData ? orderData.data[0].id : null,
                                first_name: name.message,
                                last_name: surname.message,
                                company: firm,
                                nip: nip,
                                orderMethod: gadgetInCart
                                    ? orderMethod.method
                                    : false,
                                address_1: address.message,
                                city: city.message,
                                postCode: postalCode.message,
                                email: email.message,
                                phone: telephone,
                                orderNotes: orderNotes,
                            },
                        },
                    ],
                })
                .then(response => {
                    setUserData(response.data)
                    setLocalStorage("user", JSON.stringify(response.data))
                    navigate(routes.paymentConfirmation, {
                        state: { freeCart: true },
                    })
                    return
                })
        }
        const hash = md5(`56789&${totalCost}&3214&xxuQ2noAZXGt31Lu`)
        fetch(
            `https://secure.tpay.com/api/gw/${process.env.GATSBY_TPAY_API_KEY}/transaction/create`,
            {
                method: "POST",
                body: JSON.stringify({
                    "id": 56789,
                    "amount": totalCost,
                    "crc": 3214,
                    "md5sum": hash,
                    "description": "Akademia GOPR",
                    "api_password": `${process.env.GATSBY_TPAY_API_PASSWORD}`,
                    "email": email.message,
                    "group": payment,
                    "name": name.message,
                    "return_url": `${window.location.origin}${routes.paymentConfirmation}`,
                }),
            }
        )
            .then(response => response.json())
            .then(data => {
                axios
                    .put(`customers/${userData.id}`, {
                        meta_data: [
                            {
                                key: "transationTitle",
                                value: data.title,
                            },
                            {
                                key: "order",
                                value: {
                                    id: orderData ? orderData.data[0].id : null,
                                    first_name: name.message,
                                    last_name: surname.message,
                                    company: firm,
                                    nip: nip,
                                    orderMethod: gadgetInCart
                                        ? orderMethod.method
                                        : false,
                                    address_1: address.message,
                                    city: city.message,
                                    postCode: postalCode.message,
                                    email: email.message,
                                    phone: telephone,
                                    orderNotes: orderNotes,
                                },
                            },
                        ],
                    })
                    .then(response => {
                        setUserData(response.data)
                        setLocalStorage("user", JSON.stringify(response.data))
                        navigate(data.url)
                    })
            })
            .catch(error => {
                console.error("Error:", error)
            })
    }

    return (
        <GridContainer>
            <Headline header={header} subheader={subheader} />
            <List
                productText={productText}
                sizeText={sizeText}
                amountText={amountText}
                sumText={sumText}
                products={
                    orderData && orderData.data[0]
                        ? orderData.data[0].line_items
                        : []
                }
                order={orderData ? orderData.data[0] : null}
                loadingOrders={isLoading || isFetching}
                deleteOrderError={deleteOrderError}
            />
            {orderData &&
                orderData.data[0] &&
                orderData.data[0].line_items.length > 0 && (
                    <>
                        {gadgetInCart && (
                            <div className="grid grid-cols-8">
                                <div className="col-span-8 md:col-span-3">
                                    {orderMethod.method !== "free-delivery" && (
                                        <DeliveryMethod
                                            header={deliveryMethodText}
                                            logo={deliveryLogo}
                                            price={deliveryPrice}
                                            notesPlaceholder={
                                                deliveryNotesPlaceholder
                                            }
                                            orderNotes={orderNotes}
                                            setOrderNotes={setOrderNotes}
                                            setOrderMethod={setOrderMethod}
                                            orderMethod={orderMethod}
                                        />
                                    )}
                                </div>
                                <div className="col-span-8 md:col-span-4 md:col-start-5">
                                    <AddressData
                                        header={addressDataHeader}
                                        address={address}
                                        setAddress={setAddress}
                                        addressLabel={addressLabel}
                                        addressPlaceholder={addressPlaceholder}
                                        postalCode={postalCode}
                                        setPostalCode={setPostalCode}
                                        postalLabel={postalLabel}
                                        postalPlaceholder={postalPlaceholder}
                                        city={city}
                                        setCity={setCity}
                                        cityLabel={cityLabel}
                                        cityPlaceholder={cityPlaceholder}
                                        firm={firm}
                                        setFirm={setFirm}
                                        firmLabel={firmLabel}
                                        firmPlaceholder={firmPlaceholder}
                                        nip={nip}
                                        setNip={setNip}
                                        nipLabel={nipLabel}
                                        nipPlaceholder={nipPlaceholder}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="grid grid-cols-8">
                            <div className="col-span-8 md:col-span-3">
                                <PaymentMethods
                                    header={paymentMethodsHeader}
                                    methods={paymentMethods}
                                    payment={payment}
                                    setPayment={setPayment}
                                />
                            </div>
                            <div className="col-span-8 md:col-span-4 md:col-start-5">
                                <ContactData
                                    header={contactDataHeader}
                                    name={name}
                                    setName={setName}
                                    nameLabel={nameLabel}
                                    namePlaceholder={namePlaceholder}
                                    surname={surname}
                                    setSurname={setSurname}
                                    surnameLabel={surnameLabel}
                                    surnamePlaceholder={surnamePlaceholder}
                                    email={email}
                                    setEmail={setEmail}
                                    emailLabel={emailLabel}
                                    emailPlaceholder={emailPlaceholder}
                                    telephone={telephone}
                                    setTelephone={setTelephone}
                                    telephoneLabel={telephoneLabel}
                                    telephonePlaceholder={telephonePlaceholder}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-8">
                            <div className="md:col-span-3 col-span-8">
                                <Coupon
                                    header={couponHeader}
                                    buttonText={couponButtonText}
                                    placeholder={couponPlaceholder}
                                    setCoupon={setCoupon}
                                    applyCoupon={applyCoupon}
                                    coupon={coupon}
                                    isLoading={couponIsLoading}
                                    applyingCoupon={
                                        couponIsLoading ||
                                        isLoading ||
                                        isFetching
                                    }
                                    response={couponResponse}
                                />
                            </div>
                            <div className="col-span-8 md:col-span-4 md:col-start-5">
                                <Total
                                    buttonText={paymentButtonText}
                                    totalText={totalCostText}
                                    cost={totalCost}
                                    isLoading={isLoading || isFetching}
                                    fieldsError={fieldsError}
                                    fieldsAreFilled={fieldsAreFilled}
                                    finalizePayment={finalizePayment}
                                    setFieldsError={setFieldsError}
                                    finalizingPayment={finalizingPayment}
                                    products={orderData.data[0].line_items}
                                />
                            </div>
                        </div>
                    </>
                )}
        </GridContainer>
    )
}

export default Cart

interface props {
    acf: {
        addressDataHeader: string
        addressLabel: string
        addressPlaceholder: string
        amountText: string
        cityLabel: string
        cityPlaceholder: string
        contactDataHeader: string
        couponButtonText: string
        couponHeader: string
        couponPlaceholder: string
        couponSuccessText: string
        couponErrorText: string
        deleteOrderError: string
        deliveryLogo: gatsbyImage
        deliveryMethodText: string
        deliveryNotesPlaceholder: string
        deliveryPrice: number
        emailLabel: string
        emailPlaceholder: string
        firmLabel: string
        firmPlaceholder: string
        header: string
        loadOrdersError: string
        nameLabel: string
        namePlaceholder: string
        nipLabel: string
        nipPlaceholder: string
        paymentButtonText: string
        paymentMethodsHeader: string
        paymentMethods: Array<{
            id: number
            image: gatsbyImage
        }>
        postalCodeLabel: string
        postalCodePlaceholder: string
        productText: string
        sizeText: string
        subheader: string
        sumText: string
        surnameLabel: string
        surnamePlaceholder: string
        telephoneLabel: string
        telephonePlaceholder: string
        totalCostText: string
    }
}
