import React, { useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { breakpoints } from "@breakpoints"
import { labelVariations, gatsbyImage } from "@types"
import { useBasicQuery } from "@useBasicQuery"
import { UserContext } from "../../../context/UserContext"
import { ModalContext } from "../../../context/ModalContext"

import { H4 } from "../../../styled/headers"
import Label from "./Label"
import BronzeArrow from "@icons/bronze-arrow.svg"
import { routes } from "@routes"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
`

const ImageWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
`

const Date = styled.div`
    color: var(--medium-blue);
    font-size: var(--medium-font);
    font-weight: 700;

    ${breakpoints.lg} {
        margin-bottom: 10px;
    }
`
const Place = styled.div`
    color: var(--gray);
    font-size: var(--small-font);
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
`
const MoreButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;

    color: var(--bronze);
    font-weight: 700;
    letter-spacing: 0.03em;

    svg {
        width: 10px;
    }
`

const TrainingCard = ({
    type,
    dateStart,
    dateEnd,
    title,
    place,
    price,
    desc,
    href,
    image,
}: props) => {
    const { setNavigateAfterClosing } = useContext<any>(ModalContext)
    const { userData } = useContext<any>(UserContext)

    const {
        general: {
            acf: { readMore },
        },
    } = useBasicQuery()

    return (
        <Wrapper
            onClick={() => {
                setNavigateAfterClosing({ href, state: { title } })
                type === "free-training" && !userData
                    ? navigate(routes.knowledgeBaseLocked)
                    : navigate(href, { state: { title } })
            }}
        >
            <ImageWrapper>
                <GatsbyImage
                    alt={title}
                    image={image.localFile.childImageSharp.gatsbyImageData}
                />
                <Label type={type} price={price} />
            </ImageWrapper>
            <Content>
                {dateStart && dateEnd && (
                    <Date>{`${dateStart} - ${dateEnd}`}</Date>
                )}
                <H4
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <Place dangerouslySetInnerHTML={{ __html: place }} />
                <div
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: desc }}
                />
                <MoreButton>
                    {readMore}
                    <BronzeArrow className="ml-4" />
                </MoreButton>
            </Content>
        </Wrapper>
    )
}

export default TrainingCard

interface props {
    type: labelVariations
    dateStart: string
    dateEnd: string
    title: string
    place: string
    price: string
    desc: string
    href: string
    image: gatsbyImage
}
