import React from "react"

import { singleTraining } from "@types"
import TrainingCard from "./training-card/TrainingCard"
import { routes } from "@routes"

const TrainingCardsGrid = ({
    trainings,
    size = "3",
}: {
    trainings: Array<singleTraining>
    size?: "3" | "2"
}) => {
    return (
        <div
            data-cy="TrainingsCardsGreed"
            className={`grid sm:grid-cols-2 md:grid-cols-${size} gap-5`}
        >
            {trainings?.map(
                ({
                    acf: { dateStart, dateEnd, place, type, image },
                    shortDescription,
                    slug,
                    name,
                    price,
                    id,
                }: singleTraining) => (
                    <TrainingCard
                        key={id}
                        type={type}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        title={name}
                        place={place}
                        price={price}
                        desc={shortDescription}
                        href={
                            type === "free-training"
                                ? `${routes.knowledgeBase}`
                                : `${routes.trainingsAndEvents}/${slug}`
                        }
                        image={image}
                    />
                )
            )}
        </div>
    )
}

export default TrainingCardsGrid
