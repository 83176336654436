import React from "react"
import styled from "styled-components"
import { LabelVariations } from "@types"
import { useBasicQuery } from "@useBasicQuery"

const StyledLabel = styled.div`
    position: absolute;
    right: 20px;
    bottom: -22px;
    padding: 10px 20px;
    color: #fff;
    font-weight: 700;
    background-color: ${({ color }) => color};
    border-radius: 30px;
    display: inline-block;
`

const Label = ({ type, price }: props) => {
    const options = useBasicQuery().general.acf
    const variations = {
        "paid-training": {
            name: "price",
            color: "var(--red)",
        },
        "free-training": {
            name: "freeTraining",
            color: "var(--medium-blue)",
        },
        "event": { name: "event", color: "var(--bronze)" },
    }
    const createVariation = () => {
        if (type === "paid-training") {
            return `${options[variations[type].name]} ${price}`
        } else {
            return options[variations[type].name]
        }
    }

    return (
        <StyledLabel color={variations[type].color}>
            {createVariation()}
        </StyledLabel>
    )
}

export default Label

interface props {
    type: LabelVariations
    price: string
}
