import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
`

const Radio = styled.input`
    min-width: 25px;
    min-height: 25px;
    margin: 5px 0;
    margin-right: 5px;
    border: 1px solid var(--gray-light);
    font-size: var(--base-font);
    cursor: pointer;
`

const Label = styled.label`
    cursor: pointer;
`

const RadioButton = ({
    onClick,
    name,
    checked,
    label,
    value,
    id,
    readOnly,
}: props) => {
    return (
        <StyledWrapper>
            <Radio
                onClick={onClick}
                autoComplete="on"
                type="radio"
                value={value}
                id={id}
                name={name}
                checked={checked}
                readOnly={readOnly}
            />
            {label && (
                <Label
                    htmlFor={id}
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            )}
        </StyledWrapper>
    )
}

export default RadioButton

interface props {
    onClick?: (value: any) => void
    label?: string
    value: any
    checked?: boolean
    id: string
    name: string
    readOnly?: boolean
}
