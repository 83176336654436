import React from "react"
import styled, { keyframes } from "styled-components"
import WhiteArrow from "@icons/white-arrow.svg"

const pointingDown = keyframes`
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(10px);

	}

	100% {
		transform: translateY(0);
	}
`

const StyledButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 65px;
    height: 65px;
    background-color: var(--bronze);
    border-radius: 50%;
    font-size: var(--small-font);
    font-weight: 700;
`

const StyledArrowContainer = styled.div`
    width: 12px;
    height: 12px;
    animation: ${pointingDown} 2s infinite;
    margin-top: -8px;
    margin-bottom: 10px;

    svg {
        transform: rotate(90deg);
    }
`

const ScrollButton = ({ text, onClick }: props) => {
    return (
        <StyledButton onClick={onClick}>
            <StyledArrowContainer>
                <WhiteArrow />
            </StyledArrowContainer>
            {text}
        </StyledButton>
    )
}

export default ScrollButton

interface props {
    text: string
    onClick: () => void
}
