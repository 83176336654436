import React, { useEffect, useContext, useState } from "react"
import { navigate } from "gatsby"
import { UserContext } from "../../context/UserContext"
import axios from "../../../utils/axios"
import Spinner from "../../common/Spinner"
import { isBrowser } from "../../../utils/isBrowser"
import { useQueryClient } from "react-query"
import { GridContainer } from "../../styled/GridContainer"
import Tick from "@icons/tick.svg"
import Error from "@icons/error.svg"
import InformationBlock from "./InformationBlock"

import {
    removeLocalStorage,
    getLocalStorage,
} from "../../../utils/localStorage"
import { USER_ORDER } from "../../../consts/queries"
import { metaData } from "@types"

const PaymentConfirmation = ({ acf: { error, success, idle } }: props) => {
    const queryClient = useQueryClient()
    const { userData, setUserOrder } = useContext<any>(UserContext)

    const [icon, setIcon] = useState<undefined | any>()
    const [response, setResponse] = useState<
        undefined | typeof success | typeof error
    >(undefined)
    const [loading, setLoading] = useState(true)

    const changeOrderStatus = (order: any) => {
        const companyData = () => {
            if (order.company && order.nip) {
                return `Firma: ${order.company} NIP: ${order.nip}`
            }
            return ""
        }

        const orderMethod = () => {
            if (order.orderMethod) {
                return `Sposób dostawy: ${order.orderMethod}`
            }
            return ""
        }
        axios
            .put(`/orders/${order.id}`, {
                status: "completed",
                shipping: {
                    first_name: `Imię i Nazwisko: ${order.first_name}`,
                    last_name: order.last_name,
                    company: companyData(),
                    address_1: `Adres: ${order.address_1}, ${orderMethod()}`,
                    city: `Miasto: ${order.city}`,
                    postcode: `Kod pocztowy: ${order.postCode}`,
                    state: `Uwagi: ${
                        order.orderNotes ? order.orderNotes : "brak"
                    }`,
                },
                billing: {
                    phone: order.phone,
                },
            })
            .then(_ => {
                removeLocalStorage("orders")
                setUserOrder([])
                queryClient.invalidateQueries(USER_ORDER)

                setResponse(success)
                setIcon(<Tick />)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setResponse(error)
                setIcon(<Error />)
                console.error(error)
            })
    }

    useEffect(() => {
        if (!getLocalStorage("user")) {
            navigate("/")
        }
    }, [])

    useEffect(() => {
        if (!getLocalStorage("user")) return

        const userData = getLocalStorage("user")

        let transationTitle
        let order: any

        userData.meta_data.forEach(({ key, value }: metaData) => {
            if (key === "transationTitle") {
                transationTitle = value
            } else if (key === "order") {
                order = value
            }
        })

        const redirectedFromCart =
            isBrowser() && window.history.state && window.history.state.freeCart

        if (redirectedFromCart) {
            changeOrderStatus(order)
            return
        }

        if (!transationTitle) {
            setLoading(false)
            return
        }

        fetch(
            `https://secure.tpay.com/api/gw/${process.env.GATSBY_TPAY_API_KEY}/transaction/get`,
            {
                method: "POST",
                body: JSON.stringify({
                    "title": transationTitle,
                    "api_password": `${process.env.GATSBY_TPAY_API_PASSWORD}`,
                }),
            }
        )
            .then(response => response.json())
            .then(data => {
                if (data.status === "correct") {
                    changeOrderStatus(order)
                } else {
                    setLoading(false)
                    setResponse(error)
                    setIcon(<Error />)
                }
            })
    }, [])
    return (
        <>
            {userData ? (
                <GridContainer>
                    {loading && <Spinner big center />}
                    {response ? (
                        <InformationBlock
                            icon={icon}
                            header={response.header}
                            text={response.text}
                            link={response.link}
                            buttonText={response.buttonText}
                        />
                    ) : (
                        <>
                            {!loading && (
                                <InformationBlock
                                    header={idle.header}
                                    text={idle.text}
                                    link={idle.link}
                                    buttonText={idle.buttonText}
                                />
                            )}
                        </>
                    )}
                </GridContainer>
            ) : null}
        </>
    )
}

export default PaymentConfirmation

type values = {
    buttonText: string
    header: string
    link: string
    text: string
}

interface props {
    acf: {
        error: values
        success: values
        idle: values
    }
}
