import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { routes } from "@routes"
import { breakpoints } from "@breakpoints"
import { gatsbyImage } from "@types"
import { H4 } from "../../styled/headers"
import { Button } from "../../common/Button"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    margin-top: 10px;
    border: 1px solid var(--light-gray);
    border-radius: 20px;

    ${breakpoints.lg} {
        padding: 30px;
    }
`

const StyledImage = styled.div`
    width: 200px;
    margin: 0 auto 20px auto;

    ${breakpoints.md} {
        width: 250px;
    }
`

const StyledDesc = styled.span`
    display: inline-block;
    margin: 15px 0 auto;
    font-size: var(--small-font);

    ${breakpoints.lg} {
        font-size: var(--base-font);

        margin: 20px 0 auto;
    }
`

const StyledWrapperInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
`

const StyledPriceLabel = styled.span`
    color: var(--light-blue);
`
const StyledPriceValue = styled.span`
    color: var(--dark-blue);
`

const StyledPriceWrapper = styled.div`
    font-size: var(--big-font);
    font-weight: 700;

    ${breakpoints.lg} {
        font-size: var(--xlarge-font);
    }
`

const Gadget = ({ image, name, desc, slug, price, buttonText }: props) => {
    return (
        <StyledWrapper>
            <StyledImage>
                <Link to={`${routes.shop}/${slug}`}>
                    <GatsbyImage
                        alt={name}
                        image={image.localFile.childImageSharp.gatsbyImageData}
                    />
                </Link>
            </StyledImage>
            <H4 dangerouslySetInnerHTML={{ __html: name }} />
            <StyledDesc dangerouslySetInnerHTML={{ __html: desc }} />
            <StyledWrapperInner>
                <StyledPriceWrapper>
                    <StyledPriceLabel
                        dangerouslySetInnerHTML={{ __html: `${price.label} ` }}
                    />
                    <StyledPriceValue
                        dangerouslySetInnerHTML={{ __html: price.value }}
                    />
                </StyledPriceWrapper>

                <Button
                    cypressId="buyGadgetButton"
                    isLink
                    href={`${routes.shop}/${slug}`}
                    icon="cart"
                >
                    {buttonText}
                </Button>
            </StyledWrapperInner>
        </StyledWrapper>
    )
}

export default Gadget

interface props {
    image: gatsbyImage
    name: string
    desc: string
    slug: string
    price: {
        label: string
        value: string
    }
    buttonText?: string
}
