import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpOffer {
                    nodes {
                        title
                        content
                        id
                    }
                }
                allWpProduct(
                    filter: { acf: { type: { in: ["paid-training"] } } }
                ) {
                    nodes {
                        acf {
                            offerType {
                                ... on WpOffer {
                                    title
                                }
                            }
                            dateEnd
                            dateStart
                            place
                            type
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                        id
                        name
                        slug
                        shortDescription
                        ... on WpSimpleProduct {
                            price
                        }
                    }
                }
            }
        `
    )
    return [data.allWpProduct.nodes, data.allWpOffer.nodes]
}
