import React, { useState } from "react"
import styled from "styled-components"
import Headline from "../../common/Headline"
import { GridContainer } from "../../styled/GridContainer"
import { useQuery } from "./useQuery"
import TabsMenu from "../../common/TabsMenu"
import TrainingCardsGrid from "../trainings-list/TrainingCardsGrid"
import { Content } from "../../styled/Content"
import { H4 } from "../../styled/headers"

const TabContent = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
    margin: 30px 0;
`

const GridWrapper = styled.div`
    margin-top: 20px;
`

const HeaderWrapper = styled.div`
    margin-top: 50px;
`

const MenuWrapper = styled.div`
    position: relative;
    padding-bottom: 30px;

    &:after {
        content: "";
        position: absolute;
        right: -20px;
        top: -100px;
        width: 100vw;
        height: calc(100% + 100px);
        background-color: var(--medium-gray);
        z-index: -1;
    }
`

const OfferTabs = ({ acf: { header, subheader } }: props) => {
    const [trainings, offer] = useQuery()
    const menuItems = offer.map((offer: singleOffer) => ({
        id: offer.id,
        title: offer.title,
        disabled: false,
    }))
    const [active, setActive] = useState(menuItems[0]?.title)

    const formatOffer = () => {
        const foramttedOffer = offer.map((offer: singleOffer) => {
            return { offer, assigedTrainings: [] }
        })
        return foramttedOffer
    }

    const assignedTrainingToOffer = (): any[] => {
        let formattedOffer = formatOffer()
        trainings.forEach((training: any) => {
            formattedOffer.forEach((singleOffer: singleOfferFormatted) => {
                if (singleOffer.offer.title === training.acf.offerType?.title) {
                    singleOffer.assigedTrainings.push(training)
                }
            })
        })
        return formattedOffer
    }
    return (
        <GridContainer>
            <div className="grid grid-cols-12">
                <MenuWrapper className="col-span-12 md:col-span-4">
                    <Headline header={header} subheader={subheader} />

                    <TabsMenu
                        active={active}
                        setActive={setActive}
                        items={menuItems}
                    />
                </MenuWrapper>
                {assignedTrainingToOffer().map(item => (
                    <TabContent
                        key={item.offer.title}
                        active={active === item.offer.title}
                        className="col-span-12 md:col-span-7 md:col-start-6"
                    >
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: item.offer.content,
                            }}
                        />

                        {item.assigedTrainings.length > 0 && (
                            <>
                                <HeaderWrapper>
                                    <H4>Szkolenia z tej oferty:</H4>
                                </HeaderWrapper>
                                <GridWrapper>
                                    <TrainingCardsGrid
                                        size="2"
                                        trainings={item.assigedTrainings}
                                    />
                                </GridWrapper>
                            </>
                        )}
                    </TabContent>
                ))}
            </div>
        </GridContainer>
    )
}

export default OfferTabs

interface props {
    acf: {
        header: string
        subheader: string
    }
}

interface singleOffer {
    id: string
    title: string
    content: string
}

interface singleOfferFormatted {
    offer: singleOffer
    assigedTrainings: any[]
}
