import React, { useState, useContext } from "react"
import styled from "styled-components"
import { GridContainer } from "../../styled/GridContainer"
import { UserContext } from "../../context/UserContext"
import { useQuery } from "react-query"
import { COMPLETED_ORDERS } from "../../../consts/queries"
import axios from "../../../utils/axios"
import Menu from "./menu/Menu"
import Account from "./account/Account"
import Data from "./data/Data"
import Orders from "./orders/Orders"
import Trainings from "./trainings/Trainings"
import Contact from "./contact/Contact"
import { formatOrders } from "./formatOrders"

const Content = styled.div`
    margin-bottom: 50px;
`

const UserProfile = ({
    acf: { account, contact, data, orders, trainings },
}: props) => {
    const [active, setActive] = useState(account.menuText)
    const { userData, setUserData } = useContext<any>(UserContext)

    const { isLoading, data: userOrders } = useQuery(
        COMPLETED_ORDERS,
        () =>
            axios.get(
                `orders?customer=${userData.id}&status=completed&per_page=99`
            ),
        {
            enabled: userData ? true : false,
        }
    )

    return (
        <GridContainer className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-3 lg:col-span-3">
                <Menu
                    active={active}
                    setActive={setActive}
                    setUserData={setUserData}
                    items={[
                        account.menuText,
                        data.menuText,
                        orders.menuText,
                        trainings.menuText,
                        contact.menuText,
                    ]}
                />
            </div>
            <Content className="col-span-12 md:col-span-9 lg:col-span-9">
                <Account
                    isLoading={isLoading}
                    trainings={
                        userOrders
                            ? formatOrders(userOrders).formattedTrainings.slice(
                                  0,
                                  2
                              )
                            : []
                    }
                    data={account}
                    userName={
                        userData
                            ? userData.first_name !== ""
                                ? userData.first_name
                                : userData.email
                            : ""
                    }
                    active={active === account.menuText}
                    changeTab={() => setActive(trainings.menuText)}
                />
                <Data
                    setUserData={setUserData}
                    userData={userData ? userData : undefined}
                    data={data}
                    active={active === data.menuText}
                />
                <Orders
                    goToCourseText={account.goToCourseText}
                    readMoreTrainingText={account.readMoreTrainingText}
                    readMoreGadgetText={account.readMoreGadgetText}
                    orders={
                        userOrders
                            ? formatOrders(userOrders).formattedOrders
                            : []
                    }
                    data={orders}
                    active={active === orders.menuText}
                />
                <Trainings
                    goToCourseText={account.goToCourseText}
                    readMoreText={account.readMoreTrainingText}
                    noPurchasedText={account.noPurchasedText}
                    data={trainings}
                    trainings={
                        userOrders
                            ? formatOrders(userOrders).formattedTrainings
                            : []
                    }
                    active={active === trainings.menuText}
                />
                <Contact data={contact} active={active === contact.menuText} />
            </Content>
        </GridContainer>
    )
}

export default UserProfile

interface props {
    acf: {
        account: {
            content: string
            goToCourseText: string
            header: string
            menuText: string
            readMoreTrainingText: string
            readMoreGadgetText: string
            recentPurchasedText: string
            noPurchasedText: string
            seeMoreText: string
            subheader: string
        }
        contact: {
            content: string
            header: string
            links: Array<{
                link: string
                text: string
                external: boolean
            }>
            menuText: string
            subheader: string
            usefullLinksText: string
        }
        data: {
            menuText: string
            subheader: string
            contactHeader: string
            addressHeader: string
            buttonText: string
            successfulText: string
            errorText: string
            fillFieldsText: string
            resetPassword: {
                buttonText: string
                errorMessage: string
                header: string
                newPasswordLabel: string
                newPasswordPlaceholder: string
                oldPasswordLabel: string
                oldPasswordPlaceholder: string
                repeatPasswordLabel: string
                repeatPasswordPlaceholder: string
                successfullMessage: string
            }
        }
        orders: {
            deliveryDateText: string
            header: string
            menuText: string
            orderDateText: string
            orderNumberText: string
            paymentStatusText: string
            subheader: string
            totalCostText: string
            trackDeliveryText: string
        }
        trainings: {
            content: string
            header: string
            menuText: string
            subheader: string
        }
    }
}
