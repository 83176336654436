import React from "react"

const Email = ({ email, text }: props) => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <a
                style={{ color: "var(--light-blue)" }}
                href={`mailto:${email}`}
                dangerouslySetInnerHTML={{ __html: email }}
            />
        </div>
    )
}

export default Email

interface props {
    email: string
    text: string
}
