import React, { useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    cursor: pointer;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -35px;
        width: 100%;
        height: 1px;
        background-color: var(--light-gray);
    }
`

const SelectWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid var(--light-gray);
    overflow: hidden;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 60px;
`

const Selected = styled.div<{ expanded: boolean }>`
    position: relative;

    color: var(--light-blue);
    padding: 10px;

    &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid var(--bronze);
        transform: ${({ expanded }) =>
            expanded ? "rotate(-90deg)" : "rotate(0deg)"};
        transition: 0.3s;
    }
`

const Select = styled.div<{ expanded: boolean }>`
    height: ${({ expanded }) => (expanded ? "auto" : "0px")};
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const Option = styled.span<{ active: boolean }>`
    padding: 10px;
    border-top: 1px solid var(--light-gray);
    color: ${({ active }) => (active ? "var(--light-blue)" : "inital")};

    &:hover {
        color: var(--light-blue);
    }
`

const Filter = ({ header, options, filter, setFilter }: props) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <Wrapper onClick={() => setExpanded(!expanded)}>
            <strong>{header}</strong>
            <SelectWrapper>
                <Selected
                    expanded={expanded}
                    dangerouslySetInnerHTML={{ __html: filter.title }}
                />
                <Select expanded={expanded}>
                    {options.map(option => (
                        <Option
                            key={option.title}
                            active={filter.title === option.title}
                            onClick={() => setFilter(option)}
                            dangerouslySetInnerHTML={{ __html: option.title }}
                        />
                    ))}
                </Select>
            </SelectWrapper>
        </Wrapper>
    )
}

export default Filter

interface props {
    header: string
    options: Array<{ title: string; type: string }>
    setFilter: (any: any) => void
    filter: { title: string; type: string }
}
