import { gatsbyImage } from "@types"
import { graphql, useStaticQuery } from "gatsby"

export const useProductsQuery = () => {
    const data: query = useStaticQuery(
        graphql`
            query {
                allWpProduct(
                    filter: {
                        acf: {
                            type: { in: ["paid-training", "free-training"] }
                        }
                    }
                ) {
                    nodes {
                        acf {
                            dateEnd
                            dateStart
                            place
                            type
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                            knowledgeBaseDisabledText
                            knowledgeBaseContent
                        }
                        id
                        name
                        slug
                        shortDescription
                        ... on WpSimpleProduct {
                            price
                        }
                    }
                }
            }
        `
    )
    return data.allWpProduct.nodes
}

interface query {
    allWpProduct: {
        nodes: Array<{
            id: string
            name: string
            slug: string
            content: string
            shortDescription: string
            price: string
            acf: {
                dateEnd: string
                dateStart: string
                place: string
                type: string
                image: gatsbyImage
                knowledgeBaseDisabledText: string
                knowledgeBaseContent: string
            }
        }>
    }
}
