import { useState, useEffect } from "react"
import { useQuery } from "./useQuery"
import { singleTraining } from "@types"
import { formatDate } from "../../../utils/formatDate"

const TRAININGS_TO_SHOW = 6

export const usefiltrTrainings = (
    upcoming: boolean,
    freeTrainingsOnly: boolean
) => {
    const [trainings, setTrainings] = useState<Array<singleTraining>>(
        useQuery()
    )

    useEffect(() => {
        if (trainings && trainings.length > 0) {
            if (freeTrainingsOnly) {
                const freeTrainings = trainings.filter(({ acf: { type } }) => {
                    return type === "free-training"
                })
                if (upcoming) {
                    freeTrainings.slice(0, TRAININGS_TO_SHOW)
                }
                setTrainings(freeTrainings)
            } else {
                const filteredTrainings = trainings
                    .filter(({ acf: { type } }) => {
                        return type !== "free-training"
                    })
                    .filter((training: singleTraining) => {
                        if (!training.recent) {
                            const isRecent =
                                new Date() <
                                new Date(
                                    formatDate(
                                        training.acf.dateStart
                                    ).dateToCompare
                                )
                            training.dateToCompare = formatDate(
                                training.acf.dateStart
                            ).dateToCompare

                            training.acf.dateStart = formatDate(
                                training.acf.dateStart
                            ).dateToDisplay
                            training.recent = isRecent
                            return isRecent
                        } else {
                            return training
                        }
                    })
                filteredTrainings
                    .sort((a, b) => {
                        return (
                            //@ts-ignore
                            new Date(b.dateToCompare) -
                            //@ts-ignore
                            new Date(a.dateToCompare)
                        )
                    })
                    .reverse()
                if (filteredTrainings.length > TRAININGS_TO_SHOW && upcoming) {
                    setTrainings(filteredTrainings.slice(0, TRAININGS_TO_SHOW))
                } else {
                    setTrainings(filteredTrainings)
                }
            }
        }
    }, [])
    return trainings
}
