import React from "react"
import styled from "styled-components"
import Headline from "../../common/Headline"
import { GridContainer } from "../../styled/GridContainer"
import { gatsbyImage } from "@types"
import Section from "./Section"
import { breakpoints } from "@breakpoints"
import LazyLoad from "react-lazyload"

const Wrapper = styled.div`
    position: relative;
    margin-bottom: -50px;
    padding-bottom: 10px;

    ${breakpoints.md} {
        padding-bottom: 50px;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% - 250px);
        bottom: 0;
        background-color: var(--medium-gray);
        z-index: -1;
    }
`

const HeadlineWrapper = styled.div`
    ${breakpoints.md} {
        margin-bottom: 50px;
    }
`

const Partners = ({ acf: { header, subheader, sections } }: props) => {
    return (
        <LazyLoad>
            <Wrapper>
                <GridContainer>
                    <HeadlineWrapper>
                        <Headline subheader={subheader} header={header} />
                    </HeadlineWrapper>
                    {sections?.map(({ header, logos }, index) => (
                        <Section key={index} logos={logos} header={header} />
                    ))}
                </GridContainer>
            </Wrapper>
        </LazyLoad>
    )
}

export default Partners

interface props {
    acf: {
        header: string
        subheader: string
        sections: Array<{
            header: string
            logos: Array<{
                description: string
                link: string
                image: gatsbyImage
            }>
        }>
    }
}
