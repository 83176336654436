import React from "react"
import styled from "styled-components"
import TopBar from "./TopBar"
import Orders from "./Orders"
import Spinner from "../../../common/Spinner"
import { H2 } from "../../../styled/headers"

const StyledList = styled.div`
    border-radius: 20px;
    border: 1px solid var(--light-gray);
    margin-bottom: 50px;
    overflow: hidden;
`

const SpinnerContainer = styled.div`
    margin: 20px 0;
`

const List = ({
    loadingOrders,
    deleteOrderError,
    products,
    order,
    productText,
    sizeText,
    amountText,
    sumText,
}: props) => {
    const getMetaValue = (meta_data: metaData, orderKey: string) => {
        let value = meta_data.filter(({ key }) => {
            return key === orderKey
        })
        if (value.length > 0) {
            value = value[0].value
        } else {
            //@ts-ignore
            value = undefined
        }
        return value
    }

    const formattedProducts = () => {
        if (products) {
            return products.map(product => {
                return {
                    slug: getMetaValue(product.meta_data, "slug"),
                    id: product.id,
                    image: getMetaValue(product.meta_data, "image"),
                    name: product.name,
                    price: product.price.toFixed(2),
                    totalPrice: product.total,
                    priceReduced: product.total !== product.subtotal,
                    amount: product.quantity,
                    place: getMetaValue(product.meta_data, "place"),
                    date: getMetaValue(product.meta_data, "date"),
                    meta_data: product.meta_data,
                    currency: order.currency_symbol,
                }
            })
        }
    }

    return (
        <StyledList>
            <TopBar
                productText={productText}
                sizeText={sizeText}
                amountText={amountText}
                sumText={sumText}
            />
            {loadingOrders ? (
                <SpinnerContainer>
                    <Spinner center big />
                </SpinnerContainer>
            ) : (
                <>
                    {products.length > 0 ? (
                        <Orders
                            productText={productText}
                            sizeText={sizeText}
                            amountText={amountText}
                            sumText={sumText}
                            orderId={order ? order.id : null}
                            //@ts-ignore
                            products={formattedProducts()}
                            deleteOrderError={deleteOrderError}
                        />
                    ) : (
                        <H2 style={{ textAlign: "center", margin: "20px 0" }}>
                            Koszyk jest pusty
                        </H2>
                    )}
                </>
            )}
        </StyledList>
    )
}

export default List

interface props {
    loadingOrders: boolean
    deleteOrderError: string
    products: Array<any>
    order: any
    productText: string
    sizeText: string
    amountText: string
    sumText: string
}

type metaData = Array<{ key: string; value: any }>
