import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpProduct(filter: { acf: { type: { in: "gadget" } } }) {
                    nodes {
                        acf {
                            gadgetImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                        id
                        name
                        slug
                        shortDescription
                        ... on WpSimpleProduct {
                            price
                        }
                    }
                }
            }
        `
    )
    return data.allWpProduct.nodes
}
