import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "../../common/Button"
import Spinner from "../../common/Spinner"
import axios from "../../../utils/axios"
import * as axiosModule from "axios"

const StyledWrapper = styled.div`
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    margin-bottom: 30px;
`

const StyledCost = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid var(--light-gray);
    font-size: 30px;
    color: var(--dark-blue);
    font-weight: 700;
    height: 85px;
    padding-right: 20px;
`

const StyledButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 85px;
    padding-right: 20px;
`

const StyledError = styled.span`
    display: block;
    text-align: right;
    margin: 20px;
    font-weight: 700;
    font-size: var(--base-font);
    color: var(--red);
`

const Total = ({
    buttonText,
    totalText,
    cost,
    finalizePayment,
    fieldsAreFilled,
    setFieldsError,
    finalizingPayment,
    isLoading,
    fieldsError,
    products,
}: props) => {
    const [productsNotAvailable, setProductsNotAvailable] = useState<
        [] | Array<availableProducts>
    >([])
    const [checkingQuantity, setCheckingQuantity] = useState(false)

    // merge the same line_items as one product with quantity added
    const mergedProducts = () => {
        let settings: settings = {
            ids: [],
            products: [],
        }

        products.forEach(({ product_id, quantity }) => {
            if (settings.products.length > 0) {
                settings.products.forEach((product: product) => {
                    if (product.id === product_id) {
                        product.quantity += quantity
                    } else if (!settings.ids.includes(product_id)) {
                        settings.ids.push(product_id)
                        settings.products.push({
                            id: product_id,
                            quantity: quantity,
                        })
                    }
                })
            } else {
                settings.ids.push(product_id)
                settings.products.push({
                    id: product_id,
                    quantity: quantity,
                })
            }
        })

        return settings.products
    }

    const checkProductAvailability = () => {
        if (!fieldsAreFilled()) {
            setFieldsError(true)
            return
        }
        setProductsNotAvailable([])
        setCheckingQuantity(true)

        axiosModule
            //@ts-ignore
            .all(
                mergedProducts().map(({ id }: product) =>
                    axios.get(`products/${id}`)
                )
            )
            .then(
                //@ts-ignore
                axiosModule.spread((...responses) => {
                    let productsAreInStock = true
                    responses.forEach(res => {
                        mergedProducts().forEach(
                            ({ id, quantity }: product) => {
                                if (id === res.data.id) {
                                    if (quantity > res.data.stock_quantity) {
                                        productsAreInStock = false
                                        setProductsNotAvailable(prev => [
                                            ...prev,
                                            {
                                                name: res.data.name,
                                                quantity:
                                                    res.data.stock_quantity,
                                            },
                                        ])
                                    }
                                }
                            }
                        )
                    })
                    setCheckingQuantity(false)
                    if (productsAreInStock) {
                        finalizePayment()
                    }
                })
            )
    }

    return (
        <StyledWrapper>
            <StyledCost>
                {totalText} {isLoading ? <Spinner /> : `${cost} zł`}
            </StyledCost>

            <StyledButtonWrapper>
                <Button
                    cypressId="goToPaymentButton"
                    onClick={() => {
                        checkProductAvailability()
                    }}
                    loading={finalizingPayment || checkingQuantity}
                >
                    {buttonText}
                </Button>
            </StyledButtonWrapper>
            {fieldsError && (
                <StyledError>
                    Poprawnie wypełnij wszystkie wymagane pola!
                </StyledError>
            )}
            {productsNotAvailable.map(
                (product: availableProducts, index: number) => (
                    <StyledError key={index}>
                        {product.name}: ten produkt nie jest już dostępny w
                        wybranej przez Ciebie ilości <br /> (dostępna ilość:
                        {product.quantity})
                    </StyledError>
                )
            )}
        </StyledWrapper>
    )
}

export default Total

interface props {
    buttonText: string
    totalText: string
    isLoading: boolean
    products: Array<{
        product_id: number
        quantity: number
    }>
    cost: string
    finalizingPayment: boolean
    finalizePayment: () => void
    fieldsAreFilled: () => boolean
    setFieldsError: (error: boolean) => void
    fieldsError: boolean
}

interface settings {
    ids: number[]
    products: any
}

interface product {
    id: number
    quantity: number
}

interface availableProducts {
    name: string
    quantity: number
}
