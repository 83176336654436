import React from "react"
import styled from "styled-components"
import { useBasicQuery } from "@useBasicQuery"
import { H4 } from "../../../styled/headers"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    display: none;
    padding: 20px;
    background-color: var(--medium-gray);

    ${breakpoints.md} {
        display: grid;
    }
`

const TopBar = ({ productText, amountText, sumText }: props) => {
    const {
        general: {
            acf: { price: priceText },
        },
    } = useBasicQuery()
    return (
        <Wrapper className="grid-cols-8">
            <H4
                small
                className="col-span-3"
                dangerouslySetInnerHTML={{ __html: productText }}
            />
            <H4 small dangerouslySetInnerHTML={{ __html: "" }} />
            <H4 small dangerouslySetInnerHTML={{ __html: priceText }} />
            <H4 small dangerouslySetInnerHTML={{ __html: amountText }} />
            <H4
                small
                className="col-span-2"
                dangerouslySetInnerHTML={{ __html: sumText }}
            />
        </Wrapper>
    )
}

export default TopBar

interface props {
    productText: string
    amountText: string
    sumText: string
}
