import {
    AdBanner,
    BasicContent,
    Cart,
    Contact,
    GadgetsList,
    Instructors,
    KnowledgeBase,
    MainBanner,
    OfferTabs,
    Partners,
    PaymentConfirmation,
    TrainingsList,
    UserProfile,
} from "../../../sections"
import WithMargin from "../../../hoc/withMargin"

export const config = {
    "instructors": {
        section: WithMargin(Instructors),
        acf: "instructorsAcf",
    },
    "gadgets-list": {
        section: WithMargin(GadgetsList),
        acf: "gadgetsListAcf",
    },
    "partners": {
        section: WithMargin(Partners),
        acf: "partnersAcf",
    },
    "payment-confirmation": {
        section: PaymentConfirmation,
        acf: "paymentConfirmationAcf",
    },
    "trainings-list": {
        section: WithMargin(TrainingsList),
        acf: "trainingsListAcf",
    },
    "ad-banner": {
        section: WithMargin(AdBanner),
        acf: "adBannerAcf",
    },
    "main-banner": {
        section: WithMargin(MainBanner),
        acf: "mainBannerAcf",
    },
    "cart": {
        section: WithMargin(Cart),
        acf: "cartAcf",
    },
    "contact": {
        section: WithMargin(Contact),
        acf: "contactAcf",
    },
    "knowledge-base": {
        section: WithMargin(KnowledgeBase),
        acf: "knowledgeBaseAcf",
    },
    "user-profile": {
        section: WithMargin(UserProfile),
        acf: "userProfileAcf",
    },
    "basic-content": {
        section: WithMargin(BasicContent),
        acf: "basicContentAcf",
    },
    "offer-tabs": {
        section: WithMargin(OfferTabs),
        acf: "offerTabsAcf",
    },
}
