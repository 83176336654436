import React from "react"
import styled from "styled-components"
import { Button } from "../../../common/Button"
import { breakpoints } from "@breakpoints"
import { H4 } from "../../../styled/headers"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;

    ${breakpoints.md} {
        flex-direction: row;
    }
`

const Header = ({ text, buttonText, changeTab }: props) => {
    return (
        <Wrapper>
            <H4 dangerouslySetInnerHTML={{ __html: text }} />
            <Button outline icon="arrow" onClick={changeTab}>
                {buttonText}
            </Button>
        </Wrapper>
    )
}

export default Header

interface props {
    text: string
    buttonText: string
    changeTab: () => void
}
