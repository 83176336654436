import React from "react"
import styled from "styled-components"

import { H4 } from "../../styled/headers"
import Input from "../../common/form/Input"
import { breakpoints } from "@breakpoints"

const StyledTwoColumns = styled.div`
    margin-top: 30px;
    grid-gap: 20px;
    justify-content: space-between;

    ${breakpoints.sm} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const ContactData = ({
    header,
    name,
    setName,
    nameLabel,
    namePlaceholder,
    surname,
    setSurname,
    surnameLabel,
    surnamePlaceholder,
    email,
    setEmail,
    emailLabel,
    emailPlaceholder,
    telephone,
    setTelephone,
    telephoneLabel,
    telephonePlaceholder,
}: props) => {
    return (
        <div>
            <H4 dangerouslySetInnerHTML={{ __html: header }} />
            <StyledTwoColumns>
                <Input
                    value={name.message}
                    onChange={e =>
                        setName({
                            message: e,
                            error: false,
                        })
                    }
                    error={name.error}
                    label={nameLabel}
                    type="text"
                    id="name"
                    placeholder={namePlaceholder}
                    required
                />
                <Input
                    value={surname.message}
                    onChange={e =>
                        setSurname({
                            message: e,
                            error: false,
                        })
                    }
                    error={surname.error}
                    label={surnameLabel}
                    type="text"
                    id="surname"
                    placeholder={surnamePlaceholder}
                    required
                />
            </StyledTwoColumns>

            <StyledTwoColumns>
                <Input
                    value={email.message}
                    onChange={e =>
                        setEmail({
                            message: e,
                            error: false,
                        })
                    }
                    error={email.error}
                    label={emailLabel}
                    type="email"
                    id="email"
                    placeholder={emailPlaceholder}
                    required
                />
                <Input
                    value={telephone}
                    onChange={e => setTelephone(e)}
                    label={telephoneLabel}
                    type="number"
                    id="telephone"
                    placeholder={telephonePlaceholder}
                />
            </StyledTwoColumns>
        </div>
    )
}

export default ContactData

type setInput = ({
    message,
    error,
}: {
    message: string
    error: boolean
}) => void

interface input {
    message: string
    error: boolean
}

interface props {
    header: string
    name: input
    setName: setInput
    nameLabel: string
    namePlaceholder: string
    surname: input
    setSurname: setInput
    surnameLabel: string
    surnamePlaceholder: string
    email: input
    setEmail: setInput
    emailLabel: string
    emailPlaceholder: string
    telephone: string
    setTelephone: (telephone: string) => void
    telephoneLabel: string
    telephonePlaceholder: string
}
