import React from "react"
import styled from "styled-components"
import Headline from "../../../common/Headline"
import { useBasicQuery } from "@useBasicQuery"
import Input from "../../../common/form/Input"
import { TwoColums } from "./styled"

const Wrapper = styled.form`
    margin-bottom: 50px;
`

const Contact = ({
    header,
    subheader,
    name,
    setName,
    surname,
    setSurname,
    email,
    setEmail,
    telephone,
    setTelephone,
}: props) => {
    const {
        forms: {
            acf: {
                emailLabel,
                emailPlaceholder,
                nameLabel,
                namePlaceholder,
                surnameLabel,
                surnamePlaceholder,
                telephoneLabel,
                telephonePlaceholder,
            },
        },
    } = useBasicQuery()
    return (
        <Wrapper>
            <Headline subheader={subheader} header={header} />
            <TwoColums>
                <Input
                    value={name.message}
                    onChange={e =>
                        setName({
                            message: e,
                            error: false,
                        })
                    }
                    error={name.error}
                    label={nameLabel}
                    type="text"
                    id="name"
                    placeholder={namePlaceholder}
                    required
                />
                <Input
                    value={surname.message}
                    onChange={e =>
                        setSurname({
                            message: e,
                            error: false,
                        })
                    }
                    error={surname.error}
                    label={surnameLabel}
                    type="text"
                    id="surname"
                    placeholder={surnamePlaceholder}
                    required
                />
            </TwoColums>
            <TwoColums>
                <Input
                    value={email.message}
                    onChange={e =>
                        setEmail({
                            message: e,
                            error: false,
                        })
                    }
                    error={email.error}
                    label={emailLabel}
                    type="email"
                    id="email"
                    placeholder={emailPlaceholder}
                    required
                />
                <Input
                    value={telephone}
                    onChange={e => setTelephone(e)}
                    label={telephoneLabel}
                    type="number"
                    id="telephone"
                    placeholder={telephonePlaceholder}
                />
            </TwoColums>
        </Wrapper>
    )
}

export default Contact

interface input {
    message: string
    error: boolean
}

type setInput = ({
    message,
    error,
}: {
    message: string
    error: boolean
}) => void

interface props {
    header: string
    subheader: string
    name: input

    setName: setInput
    surname: input
    setSurname: setInput
    email: input
    setEmail: setInput
    telephone: string
    setTelephone: (telephone: string) => void
}
