import { useEffect } from "react"
import { navigate } from "gatsby"
import { getLocalStorage } from "../utils/localStorage"

export const useUserIsLogged = () => {
    useEffect(() => {
        if (!getLocalStorage("user")) {
            navigate("/")
        }
    }, [])
}
