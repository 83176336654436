import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpProduct(
                    filter: {
                        acf: {
                            type: {
                                in: ["paid-training", "event", "free-training"]
                            }
                        }
                    }
                    sort: { fields: date, order: DESC }
                ) {
                    nodes {
                        acf {
                            dateEnd
                            dateStart
                            place
                            type
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                        id
                        name
                        slug
                        shortDescription
                        ... on WpSimpleProduct {
                            price
                        }
                    }
                }
            }
        `
    )
    return data.allWpProduct.nodes
}
