import React from "react"
import styled from "styled-components"
import { H2 } from "../../styled/headers"
import { Button } from "../../common/Button"

const Wrapper = styled.div`
    text-align: center;
    max-width: 600px;
    margin: 0 auto 50px;
`

const IconWrapper = styled.div`
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
`

const Text = styled.div`
    margin: 30px 0;
`

const InformationBlock = ({ icon, header, text, link, buttonText }: props) => {
    return (
        <Wrapper>
            <IconWrapper>{icon}</IconWrapper>
            <H2
                dangerouslySetInnerHTML={{
                    __html: header,
                }}
            />
            <Text
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
            {link && buttonText && (
                <Button isLink href={link}>
                    {buttonText}
                </Button>
            )}
        </Wrapper>
    )
}

export default InformationBlock

interface props {
    icon?: any
    header: string
    text: string
    link: string
    buttonText: string
}
