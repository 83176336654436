import { gatsbyImage, metaData } from "@types"
import { formatDate } from "../../../utils/formatDate"

export const formatOrders = (userOrders: userOrders) => {
    let trainings: Array<any> = []
    let formattedTrainings: Array<any> = []

    userOrders.data.forEach(({ line_items }) => {
        line_items.forEach(order => {
            order.meta_data.forEach(({ key, value }) => {
                if (key === "type" && value === "paid-training") {
                    trainings.push(order)
                }
            })
        })
    })
    const formattedOrders = userOrders.data.map(
        ({ id, total, date_paid, line_items, meta_data }) => {
            let products: Array<{
                type: string
                name: string
                image: gatsbyImage
                date: string
                place: string
                slug: string
            }> = []
            let type: string
            let date: string
            let place: string
            let slug: string
            let image: gatsbyImage
            let deliveryDate
            let deliveryLink
            line_items.forEach(({ name, meta_data }) => {
                meta_data.forEach(({ key, value }) => {
                    if (key === "date") {
                        date = value
                    } else if (key === "place") {
                        place = value
                    } else if (key === "slug") {
                        slug = value
                    } else if (key === "image") {
                        image = value
                    } else if (key === "type") {
                        type = value
                    }
                })
                products.push({
                    type,
                    name,
                    image,
                    date,
                    place,
                    slug,
                })
            })
            meta_data.forEach(({ key, value }: metaData) => {
                if (key === "delivery_date") {
                    deliveryDate = value
                } else if (key === "delivery_link") {
                    deliveryLink = value
                }
            })

            return {
                id,
                total,
                deliveryDate,
                deliveryLink,
                datePaid: date_paid,
                products,
            }
        }
    )
    if (trainings.length > 0) {
        trainings.forEach(({ name, meta_data }) => {
            let date
            let place
            let slug
            let ended
            let surveyAfter

            meta_data.forEach(({ key, value }: metaData) => {
                if (key === "date") {
                    date = value
                } else if (key === "place") {
                    place = value
                } else if (key === "slug") {
                    slug = value
                } else if (key === "dateEnd") {
                    ended =
                        new Date() > new Date(formatDate(value).dateToCompare)
                } else if (key === "surveyAfter") {
                    surveyAfter = true
                }
            })
            formattedTrainings.push({
                name,
                date,
                place,
                slug,
                ended,
                surveyAfter,
            })
        })
    }

    return {
        formattedTrainings,
        formattedOrders,
    }
}

interface userOrders {
    data: Array<{
        id: number
        total: string
        date_paid: string
        meta_data: Array<metaData>
        line_items: Array<{
            name: string
            meta_data: Array<metaData>
        }>
    }>
}
