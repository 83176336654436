import styled from "styled-components"
import { breakpoints } from "@breakpoints"

export const TwoColums = styled.div`
    margin-top: 30px;
    grid-gap: 20px;
    justify-content: space-between;

    ${breakpoints.sm} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`
