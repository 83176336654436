import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { gatsbyImage } from "@types"

import Headline from "../../common/Headline"
import Instructor from "./Instructor"
import { Button } from "../../common/Button"
import { GridContainer } from "../../styled/GridContainer"
import { useQuery } from "./useQuery"
import LazyLoad from "react-lazyload"

const ButtonWrapper = styled.div`
    margin-top: 20px;
    ${breakpoints.sm} {
        display: none;
    }
`

const Instructors = ({
    acf: { buttonLink, buttonText, header, subheader, variation, instructors },
}: props) => {
    instructors = variation === "simple" ? instructors : useQuery()
    return (
        <LazyLoad>
            <GridContainer>
                <Headline
                    subheader={subheader}
                    header={header}
                    buttonSettings={
                        variation === "simple" && buttonLink && buttonText
                            ? { text: buttonText, href: buttonLink.slug }
                            : undefined
                    }
                />

                <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-5">
                    {instructors?.map(
                        ({ title, content, acf: { position, image }, id }) => (
                            <Instructor
                                key={id}
                                name={title}
                                desc={content}
                                position={position}
                                image={image}
                            />
                        )
                    )}
                </div>
                {variation === "simple" && buttonLink && (
                    <ButtonWrapper>
                        <Button
                            isLink
                            icon="arrow"
                            outline
                            fullWidth
                            href={buttonLink?.slug}
                        >
                            {buttonText}
                        </Button>
                    </ButtonWrapper>
                )}
            </GridContainer>
        </LazyLoad>
    )
}

export default Instructors

interface props {
    acf: {
        buttonLink?: {
            slug: string
        }
        buttonText?: string
        header: string
        subheader: string
        variation: "simple" | "full"
        instructors: Array<{
            title: string
            content: string
            id: string
            acf: {
                position: string
                image: gatsbyImage
            }
        }>
    }
}
