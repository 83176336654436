import React from "react"
import styled from "styled-components"
import Headline from "../../../common/Headline"
import Infrmations from "./Informations"
import Links from "./Links"
import { breakpoints } from "@breakpoints"

const Wrappper = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
`

const Content = styled.div`
    margin-bottom: 40px;
    ${breakpoints.md} {
        width: 60%;
    }
`

const Contact = ({
    active,
    data: { header, subheader, content, links, usefullLinksText },
}: props) => {
    return (
        <Wrappper active={active}>
            <Headline header={header} subheader={subheader} />
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <Infrmations />
            <Links links={links} header={usefullLinksText} />
        </Wrappper>
    )
}

export default Contact

interface props {
    data: {
        content: string
        header: string
        links: Array<{
            link: string
            text: string
            external: boolean
        }>
        subheader: string
        usefullLinksText: string
    }
    active: boolean
}
