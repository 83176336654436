import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { TwoColums } from "./styled"
import { H2 } from "../../../styled/headers"
import Input from "../../../common/form/Input"
import { useBasicQuery } from "@useBasicQuery"

const ThreeColumns = styled.div`
    margin-top: 30px;
    grid-gap: 20px;
    justify-content: space-between;

    ${breakpoints.sm} {
        display: grid;

        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`
const Address = ({
    header,
    address,
    setAddress,
    postalCode,
    setPostalCode,
    city,
    setCity,
    firm,
    setFirm,
    nip,
    setNip,
}: props) => {
    const {
        forms: {
            acf: {
                addressLabel,
                addressPlaceholder,
                cityLabel,
                cityPlaceholder,
                firmLabel,
                firmPlaceholder,
                nipLabel,
                nipPlaceholder,
                postalLabel,
                postalPlaceholder,
            },
        },
    } = useBasicQuery()
    return (
        <form>
            <H2 dangerouslySetInnerHTML={{ __html: header }} />
            <TwoColums>
                <Input
                    value={address.message}
                    onChange={e =>
                        setAddress({
                            message: e,
                            error: false,
                        })
                    }
                    error={address.error}
                    label={addressLabel}
                    type="text"
                    id="address"
                    placeholder={addressPlaceholder}
                    required
                />
                <Input
                    value={postalCode.message}
                    onChange={e =>
                        setPostalCode({
                            message: e,
                            error: false,
                        })
                    }
                    error={postalCode.error}
                    label={postalLabel}
                    type="text"
                    id="postal-code"
                    placeholder={postalPlaceholder}
                    min={6}
                    max={6}
                    required
                />
            </TwoColums>
            <ThreeColumns>
                <Input
                    value={city.message}
                    onChange={e =>
                        setCity({
                            message: e,
                            error: false,
                        })
                    }
                    label={cityLabel}
                    error={city.error}
                    type="text"
                    id="city"
                    placeholder={cityPlaceholder}
                    required
                />
                <Input
                    value={firm}
                    onChange={e => setFirm(e)}
                    label={firmLabel}
                    type="text"
                    id="firm"
                    placeholder={firmPlaceholder}
                />
                <Input
                    value={nip}
                    onChange={e => setNip(e)}
                    label={nipLabel}
                    type="number"
                    id="nip"
                    placeholder={nipPlaceholder}
                />
            </ThreeColumns>
        </form>
    )
}

export default Address

interface input {
    message: string
    error: boolean
}

type setInput = ({
    message,
    error,
}: {
    message: string
    error: boolean
}) => void

interface props {
    header: string
    address: input
    setAddress: setInput
    postalCode: input
    setPostalCode: setInput
    city: input
    setCity: setInput
    firm: string
    setFirm: (firm: string) => void
    nip: string
    setNip: (nip: string) => void
}
