import React from "react"
import styled from "styled-components"
import Headline from "../../../common/Headline"
import Header from "./Header"
import Card from "./Card"
import { H5 } from "../../../styled/headers"
import Spinner from "../../../common/Spinner"

const Wrappper = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
`

const Account = ({
    active,
    changeTab,
    trainings,
    userName,
    isLoading,
    data: {
        content,
        goToCourseText,
        header,
        readMoreTrainingText,
        recentPurchasedText,
        noPurchasedText,
        seeMoreText,
        subheader,
    },
}: props) => {
    return (
        <Wrappper active={active}>
            <Headline header={`${header} ${userName}`} subheader={subheader} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <Header
                changeTab={changeTab}
                text={recentPurchasedText}
                buttonText={seeMoreText}
            />
            {isLoading ? (
                <Spinner center big />
            ) : (
                <>
                    {trainings.length > 0 ? (
                        trainings.map(
                            ({
                                name,
                                date,
                                place,
                                slug,
                                ended,
                                surveyAfter,
                            }: training) => (
                                <Card
                                    goToCourseText={goToCourseText}
                                    readMoreText={readMoreTrainingText}
                                    date={date}
                                    place={place}
                                    slug={slug}
                                    name={name}
                                    ended={ended}
                                    surveyAfter={surveyAfter}
                                />
                            )
                        )
                    ) : (
                        <H5
                            style={{ color: "var(--red)", marginTop: "20px" }}
                            dangerouslySetInnerHTML={{
                                __html: noPurchasedText,
                            }}
                        />
                    )}
                </>
            )}
        </Wrappper>
    )
}

export default Account

interface props {
    active: boolean
    changeTab: () => void
    userName: string
    isLoading: boolean
    trainings: [] | Array<training>
    data: {
        content: string
        goToCourseText: string
        header: string
        menuText: string
        readMoreTrainingText: string
        recentPurchasedText: string
        noPurchasedText: string
        seeMoreText: string
        subheader: string
    }
}

interface training {
    name: string
    date: string
    place: string
    slug: string
    ended: boolean
    surveyAfter: boolean
}
