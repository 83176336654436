import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { H4 } from "../../../styled/headers"

const Wrapper = styled.div`
    margin-top: 50px;
`

const linkStyles = css`
    display: block;
    color: var(--medium-blue);
    font-weight: 700;
    margin: 10px 0;
`

const ExternalLink = styled.a`
    ${linkStyles}
`
const InternalLink = styled(props => <Link {...props} />)`
    ${linkStyles}
`

const LinksList = styled.ul`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`

const Links = ({ header, links }: props) => {
    return (
        <Wrapper>
            <H4 dangerouslySetInnerHTML={{ __html: header }} />
            <LinksList>
                {links?.map(({ text, link, external }, index) => {
                    return (
                        <li key={index}>
                            {external ? (
                                <ExternalLink
                                    href={link}
                                    dangerouslySetInnerHTML={{
                                        __html: text,
                                    }}
                                />
                            ) : (
                                <InternalLink to={link}>{text}</InternalLink>
                            )}
                        </li>
                    )
                })}
            </LinksList>
        </Wrapper>
    )
}

export default Links

interface props {
    header: string
    links: Array<{
        text: string
        link: string
        external: boolean
    }>
}
