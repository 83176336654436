import React from "react"
import styled from "styled-components"
import Headline from "../../../common/Headline"
import Telephone from "./Telephone"
import Email from "./Email"

const StyledContent = styled.div`
    margin: 20px 0 40px;
`

const Content = ({
    callUsText,
    content,
    email,
    header,
    subheader,
    telephone,
    writeToUsText,
}: props) => {
    return (
        <div className="mb-10">
            <Headline header={header} subheader={subheader} />
            <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
            <Telephone number={telephone} text={callUsText} />
            <Email email={email} text={writeToUsText} />
        </div>
    )
}

export default Content

interface props {
    callUsText: string
    content: string
    email: string
    header: string
    subheader: string
    telephone: string
    writeToUsText: string
}
