import React from "react"
import styled from "styled-components"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"
import Logo from "./Logo"

const Header = styled.h2`
    font-size: var(--small-font);
    color: var(--medium-blue);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    ${breakpoints.md} {
        font-size: var(--big-font);
    }
`

const Logos = styled.div`
    display: grid;
    grid-gap: 30px;
    margin-bottom: 50px;
    margin-top: 20px;

    ${breakpoints.sm} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${breakpoints.md} {
        grid-template-columns: repeat(4, 1fr);
    }
`
const Section = ({ header, logos }: props) => {
    return (
        <div>
            <Header dangerouslySetInnerHTML={{ __html: header }} />
            <Logos>
                {logos?.map(({ description, image, link }) => (
                    <Logo
                        key={link}
                        description={description}
                        image={image}
                        link={link}
                    />
                ))}
            </Logos>
        </div>
    )
}

export default Section

interface props {
    header: string
    logos: Array<{
        description: string
        image: gatsbyImage
        link: string
    }>
}
