import React, { useState, useEffect } from "react"
import { useQueryClient } from "react-query"
import styled from "styled-components"
import { useBasicQuery } from "@useBasicQuery"
import axios from "../../../../utils/axios"
import { Button } from "../../../common/Button"
import Question from "./Question"
import Response from "../../../common/styled/Response"
import Intro from "./Intro"
import { COMPLETED_ORDERS } from "@queries"

const Wrapper = styled.div`
    margin-top: 50px;
`

const AfterSurvey = ({ orderId, trainingId, completed }: props) => {
    const queryClient = useQueryClient()
    const {
        survey: {
            acf: {
                sendButtonText,
                questions,
                successText,
                errorText,
                answerAllText,
                introText,
                completedText,
            },
        },
    } = useBasicQuery()
    const [loading, setLoading] = useState(false)
    const [fieldsError, setFieldsError] = useState(false)
    const [response, setResponse] = useState({
        message: "",
        error: false,
    })

    const [surveyParts, setSurveyParts] = useState(
        questions.map(({ question }) => ({
            question,
            answer: "",
            error: false,
        }))
    )

    const validateAnswers = () => {
        let fieldErrors = false
        setSurveyParts(prev => {
            return prev.map(part => {
                if (part.answer === "") {
                    fieldErrors = true

                    setResponse({
                        message: answerAllText,
                        error: true,
                    })
                    return { ...part, error: true }
                }
                return part
            })
        })

        return fieldErrors
    }

    useEffect(() => {
        let error = false
        surveyParts.map(({ answer }) => {
            if (answer === "") {
                error = true
            }
        })
        setFieldsError(error)
    }, [surveyParts])

    const sendSurvey = () => {
        validateAnswers()

        if (fieldsError) return

        setLoading(true)
        setResponse({
            message: "",
            error: false,
        })
        const formattedAnswers = surveyParts
            .map(
                ({ question, answer }) =>
                    `${question}: <strong>${answer}<br></strong><br>`
            )
            .join("")
        axios
            .post(`orders/${orderId}/notes`, {
                note: `${formattedAnswers}`,
            })
            .then(_ => {
                setLoading(false)
                setResponse({
                    message: successText,
                    error: false,
                })
                axios
                    .put(`orders/${orderId}`, {
                        line_items: [
                            {
                                id: trainingId,
                                meta_data: [
                                    {
                                        key: "surveyAfter",
                                        value: "completed",
                                    },
                                ],
                            },
                        ],
                    })
                    .then(_ => {
                        queryClient.invalidateQueries(COMPLETED_ORDERS)
                    })
            })
            .catch(error => {
                setLoading(false)
                setResponse({
                    message: errorText,
                    error: true,
                })
                console.error(error)
            })
    }

    return (
        <Wrapper>
            <Intro
                introText={introText}
                completedText={completedText}
                completed={completed}
            />
            {!completed && (
                <>
                    {questions &&
                        questions.map(({ question, type }, index) => (
                            <Question
                                key={question}
                                index={index}
                                setSurveyParts={setSurveyParts}
                                surveyParts={surveyParts}
                                question={question}
                                type={type}
                            />
                        ))}
                    <Button
                        loading={loading}
                        disabled={loading}
                        onClick={() => sendSurvey()}
                    >
                        {sendButtonText}
                    </Button>
                    <Response
                        error={response.error}
                        dangerouslySetInnerHTML={{ __html: response.message }}
                    />
                </>
            )}
        </Wrapper>
    )
}

export default AfterSurvey

interface props {
    completed: boolean
    orderId: number
    trainingId: number
}
