import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { GridContainer } from "../../styled/GridContainer"
import { gatsbyImage } from "@types"
import { useBasicQuery } from "@useBasicQuery"

import Content from "./content/Content"

const Contact = ({ acf: { content, header, image, subheader } }: props) => {
    const {
        contact: {
            acf: { email, telephone, writeToUsText, callUsText },
        },
    } = useBasicQuery()
    return (
        <GridContainer className="grid md:grid-cols-12">
            <div className="col-span-12 md:col-span-4">
                <Content
                    callUsText={callUsText}
                    content={content}
                    email={email}
                    header={header}
                    subheader={subheader}
                    telephone={telephone}
                    writeToUsText={writeToUsText}
                />
            </div>
            <div className="col-span-12 md:col-span-7 md:col-start-6 mb-10">
                <GatsbyImage
                    alt=""
                    image={image.localFile.childImageSharp.gatsbyImageData}
                />
            </div>
        </GridContainer>
    )
}

export default Contact

interface props {
    acf: {
        callUsText: string
        content: string
        email: string
        header: string
        image: gatsbyImage
        subheader: string
        telephone: string
        writeToUsText: string
    }
}
