import React, { useContext } from "react"
import styled from "styled-components"
import { ModalContext } from "../../context/ModalContext"
import Headline from "../../common/Headline"
import { GridContainer } from "../../styled/GridContainer"
import { Content } from "../../styled/Content"
import { Button } from "../../common/Button"
import { breakpoints } from "@breakpoints"

const ButtonWrapper = styled.div`
    margin: 30px 0;
`

const Wrapper = styled.div`
    margin-bottom: 50px;

    ${breakpoints.md} {
        max-width: 60%;
    }
`

const BasicContent = ({
    acf: {
        header,
        subheader,
        content,
        addButton,
        buttonLink,
        buttonOpenModal,
        buttonText,
    },
}: props) => {
    const { setModalActive } = useContext<any>(ModalContext)
    return (
        <GridContainer>
            <Wrapper>
                <Headline header={header} subheader={subheader} />
                <Content dangerouslySetInnerHTML={{ __html: content }} />
                {addButton && (
                    <ButtonWrapper>
                        <Button
                            onClick={
                                buttonOpenModal
                                    ? () => {
                                          setModalActive(true)
                                      }
                                    : undefined
                            }
                            isLink={buttonLink !== null && !buttonOpenModal}
                            href={buttonLink}
                        >
                            {buttonText}
                        </Button>
                    </ButtonWrapper>
                )}
            </Wrapper>
        </GridContainer>
    )
}

export default BasicContent

interface props {
    acf: {
        header: string
        subheader: string
        content: string
        addButton: boolean
        buttonLink: string
        buttonOpenModal: boolean
        buttonText: string
    }
}
