import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Contact from "./Contact"
import Address from "./Address"
import { Button } from "../../../common/Button"
import axios from "../../../../utils/axios"
import Spinner from "../../../common/Spinner"
import { setLocalStorage } from "../../../../utils/localStorage"
import ResetPassword from "./ResetPassword"

const Wrappper = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
`

const Response = styled.div<{ error?: boolean }>`
    margin-top: 10px;
    font-weight: 700;
    color: ${({ error }) => (error ? "var(--red)" : "var(--green)")};
    font-size: var(--base-font);
`

const Data = ({ setUserData, userData, data, active }: props) => {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({
        message: "",
        type: "success",
    })
    const [fieldsError, setFieldsError] = useState(false)
    const [address, setAddress] = useState({
        message: "",
        error: false,
    })
    const [postalCode, setPostalCode] = useState({
        message: "",
        error: false,
    })
    const [city, setCity] = useState({
        message: "",
        error: false,
    })
    const [firm, setFirm] = useState("")
    const [nip, setNip] = useState("")
    const [name, setName] = useState({
        message: "",
        error: false,
    })
    const [surname, setSurname] = useState({
        message: "",
        error: false,
    })
    const [email, setEmail] = useState({
        message: "",
        error: false,
    })
    const [telephone, setTelephone] = useState("")

    useEffect(() => {
        if (userData) {
            setAddress({
                message: userData.billing.address_1,
                error: false,
            })
            setPostalCode({
                message: userData.billing.postcode,
                error: false,
            })
            setFirm(userData.billing.company)
            setNip(userData.shipping.company)
            setCity({
                message: userData.billing.city,
                error: false,
            })
            setName({
                message: userData.first_name,
                error: false,
            })
            setSurname({
                message: userData.last_name,
                error: false,
            })
            setEmail({
                message: userData.email,
                error: false,
            })
            setTelephone(userData.billing.phone)
        }
    }, [userData])

    const postalCodeValid = () => {
        // prettier-ignore
        const polandPostalCode = new RegExp("^\\d{2}[- ]{0,1}\\d{3}$")
        const valid = polandPostalCode.test(postalCode.message)
        if (!valid) {
            setPostalCode(prev => ({
                message: prev.message,
                error: true,
            }))
            return false
        }
        return true
    }

    const checkFieldsValues = () => {
        setFieldsError(false)
        let fieldsNotFilled = false
        const notEmpty = (
            value: string,
            callback: ({ message, error }: callback) => void
        ) => {
            if (value === "") {
                fieldsNotFilled = true
                setFieldsError(true)
                callback({ message: "", error: true })
            }
        }

        const fieldsToCheck = [
            {
                message: name.message,
                callback: setName,
            },
            {
                message: surname.message,
                callback: setSurname,
            },
            {
                message: email.message,
                callback: setEmail,
            },
            {
                message: address.message,
                callback: setAddress,
            },
            {
                message: postalCode.message,
                callback: setPostalCode,
            },
            {
                message: city.message,
                callback: setCity,
            },
        ]

        fieldsToCheck.forEach(({ message, callback }) => {
            notEmpty(message, callback)
        })
        return fieldsNotFilled
    }

    const updateUser = () => {
        setResponse({
            message: "",
            type: "",
        })
        const fieldsNotFilled = checkFieldsValues()
        if (fieldsNotFilled) {
            setResponse({
                message: data.fillFieldsText,
                type: "error",
            })
            return
        }

        if (!postalCodeValid()) {
            setResponse({
                message: "Nieprawidłowy kod pocztowy",
                type: "error",
            })
            return
        }
        setLoading(true)
        if (!userData) return

        axios
            .put(`customers/${userData.id}`, {
                first_name: name.message,
                last_name: surname.message,
                billing: {
                    company: firm,
                    address_1: address.message,
                    city: city.message,
                    postcode: postalCode.message,
                    email: email.message,
                    phone: telephone,
                },
                shipping: {
                    company: nip,
                },
            })
            .then(response => {
                setLoading(false)
                setLocalStorage("user", JSON.stringify(response.data))
                setUserData(response.data)
                setResponse({
                    message: data.successfulText,
                    type: "success",
                })
            })
            .catch(error => {
                setLoading(false)

                setResponse({
                    message: data.errorText,
                    type: "error",
                })
                console.error(error)
            })
    }

    return (
        <Wrappper active={active}>
            <Contact
                name={name}
                setName={setName}
                surname={surname}
                setSurname={setSurname}
                email={email}
                setEmail={setEmail}
                telephone={telephone}
                setTelephone={setTelephone}
                header={data.contactHeader}
                subheader={data.subheader}
            />
            <Address
                header={data.addressHeader}
                address={address}
                setAddress={setAddress}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                city={city}
                setCity={setCity}
                firm={firm}
                setFirm={setFirm}
                nip={nip}
                setNip={setNip}
            />
            {userData ? (
                <Button onClick={() => updateUser()}>{data.buttonText}</Button>
            ) : (
                <Spinner />
            )}
            {loading && <Spinner />}

            <Response
                error={response.type === "error"}
                dangerouslySetInnerHTML={{ __html: response.message }}
            />
            <ResetPassword data={data.resetPassword} />
        </Wrappper>
    )
}

export default Data

interface props {
    setUserData: (userData: any) => void
    userData?: {
        id: number
        first_name: string
        last_name: string
        email: string
        billing: {
            address_1: string
            postcode: string
            company: string
            city: string
            phone: string
        }
        shipping: {
            company: string
        }
    }
    data: {
        subheader: string
        contactHeader: string
        addressHeader: string
        buttonText: string
        successfulText: string
        errorText: string
        fillFieldsText: string
        resetPassword: {
            buttonText: string
            errorMessage: string
            header: string
            newPasswordLabel: string
            newPasswordPlaceholder: string
            repeatPasswordLabel: string
            repeatPasswordPlaceholder: string
            successfullMessage: string
            currentPasswordLabel: string
            currentPasswordPlaceholder: string
            newPasswordEqualsCurrentError: string
            invalidCurrentPasswordError: string
            passwordRequirements: string
            passwordsNotEqualError: string
        }
    }
    active: boolean
}

interface callback {
    message: string
    error: boolean
}
