import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
`
const StyledTextArea = styled.textarea`
    padding: 10px;
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    resize: none;
    width: 100%;
    height: 100%;
    font-size: var(--base-font);

    &:focus {
        outline: none;
        border: 1px solid var(--light-blue);
    }
`

const Textarea = ({ value, onChange, placeholder }: props) => {
    return (
        <StyledWrapper>
            <StyledTextArea
                onChange={e => onChange(e.target.value)}
                autoComplete="on"
                value={value}
                placeholder={placeholder}
            />
        </StyledWrapper>
    )
}

export default Textarea

interface props {
    value: any
    onChange: (target: any) => void
    placeholder: string
}
