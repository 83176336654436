import React from "react"
import styled from "styled-components"
import Input from "../../common/form/Input"
import { H4 } from "../../styled/headers"
import { breakpoints } from "@breakpoints"

const StyledTwoColumns = styled.div`
    margin-top: 30px;
    grid-gap: 20px;
    justify-content: space-between;

    ${breakpoints.sm} {
        display: grid;

        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const StyledThreeColumns = styled.div`
    margin-top: 30px;
    grid-gap: 20px;
    justify-content: space-between;

    ${breakpoints.sm} {
        display: grid;

        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`

const AddressData = ({
    header,
    address,
    setAddress,
    addressLabel,
    addressPlaceholder,
    postalCode,
    setPostalCode,
    postalLabel,
    postalPlaceholder,
    city,
    setCity,
    cityLabel,
    cityPlaceholder,
    firm,
    setFirm,
    firmLabel,
    firmPlaceholder,
    nip,
    setNip,
    nipLabel,
    nipPlaceholder,
}: props) => {
    return (
        <div>
            <H4 dangerouslySetInnerHTML={{ __html: header }} />
            <StyledTwoColumns>
                <Input
                    value={address.message}
                    onChange={e =>
                        setAddress({
                            message: e,
                            error: false,
                        })
                    }
                    error={address.error}
                    label={addressLabel}
                    type="text"
                    id="address"
                    placeholder={addressPlaceholder}
                    required
                />
                <Input
                    value={postalCode.message}
                    onChange={e =>
                        setPostalCode({
                            message: e,
                            error: false,
                        })
                    }
                    error={postalCode.error}
                    label={postalLabel}
                    type="text"
                    id="postal-code"
                    placeholder={postalPlaceholder}
                    pattern="[0-9]{2}\-[0-9]{3}"
                    min={6}
                    max={6}
                    required
                />
            </StyledTwoColumns>
            <StyledThreeColumns>
                <Input
                    value={city.message}
                    onChange={e =>
                        setCity({
                            message: e,
                            error: false,
                        })
                    }
                    label={cityLabel}
                    error={city.error}
                    type="text"
                    id="city"
                    placeholder={cityPlaceholder}
                    required
                />
                <Input
                    value={firm}
                    onChange={e => setFirm(e)}
                    label={firmLabel}
                    type="text"
                    id="firm"
                    placeholder={firmPlaceholder}
                />
                <Input
                    value={nip}
                    onChange={e => setNip(e)}
                    label={nipLabel}
                    type="number"
                    id="nip"
                    placeholder={nipPlaceholder}
                />
            </StyledThreeColumns>
        </div>
    )
}

export default AddressData

type setInput = ({
    message,
    error,
}: {
    message: string
    error: boolean
}) => void

interface input {
    message: string
    error: boolean
}

interface props {
    header: string
    address: input
    setAddress: setInput
    addressLabel: string
    addressPlaceholder: string
    postalCode: input
    setPostalCode: setInput
    postalLabel: string
    postalPlaceholder: string
    city: input
    setCity: setInput
    cityLabel: string
    cityPlaceholder: string
    firm: string
    setFirm: (firm: string) => void
    firmLabel: string
    firmPlaceholder: string
    nip: string
    setNip: (nip: string) => void
    nipLabel: string
    nipPlaceholder: string
}
