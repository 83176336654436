import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { breakpoints } from "@breakpoints"
import { gatsbyImage } from "@types"

import { H4 } from "../../styled/headers"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0px;
    }
`

const ImageWrapper = styled.div`
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 150px;

    ${breakpoints.sm} {
        width: auto;
    }
`

const StyledPosition = styled.span`
    display: block;
    font-size: var(--base-font);
    color: var(--gray);
    margin-top: -5px;
    margin-bottom: 20px;
`

const Instructor = ({ name, desc, position, image }: props) => {
    return (
        <StyledWrapper>
            <ImageWrapper>
                <GatsbyImage
                    alt={name}
                    image={image.localFile.childImageSharp.gatsbyImageData}
                />
            </ImageWrapper>
            <H4 dangerouslySetInnerHTML={{ __html: name }} />
            <StyledPosition dangerouslySetInnerHTML={{ __html: position }} />
            <div dangerouslySetInnerHTML={{ __html: desc }} />
        </StyledWrapper>
    )
}

export default Instructor

interface props {
    name: string
    desc: string
    position: string
    image: gatsbyImage
}
