import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"

import Headline from "../../common/Headline"
import TrainingsCardGrid from "./TrainingCardsGrid"
import { singleTraining } from "@types"
import { Button } from "../../common/Button"
import { GridContainer } from "../../styled/GridContainer"
import { H4 } from "../../styled/headers"

const ButtonWrapper = styled.div`
    margin-top: 25px;

    ${breakpoints.sm} {
        display: none;
    }
`

const Presentation = ({
    buttonSettings,
    subheader,
    header,
    trainings,
}: props) => {
    return (
        <GridContainer>
            <Headline
                subheader={subheader}
                header={header}
                buttonSettings={buttonSettings}
            />

            <TrainingsCardGrid trainings={trainings} />
            {trainings.length === 0 && (
                <H4>Chwilowy brak szkoleń, nowe szkolenia już wkrótce.</H4>
            )}
            {buttonSettings && (
                <ButtonWrapper>
                    <Button
                        isLink
                        icon="arrow"
                        outline
                        fullWidth
                        href={buttonSettings.href}
                    >
                        {buttonSettings.text}
                    </Button>
                </ButtonWrapper>
            )}
        </GridContainer>
    )
}

export default Presentation

interface props {
    buttonSettings?:
        | false
        | {
              text: string
              href: string
          }
    subheader: string
    header: string
    trainings: Array<singleTraining>
}
