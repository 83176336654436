import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpInstructor {
                    nodes {
                        id
                        title
                        content
                        acf {
                            position
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.allWpInstructor.nodes
}
