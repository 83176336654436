import React from "react"
import styled from "styled-components"
import Headline from "../../../common/Headline"
import Card from "./Card"
import Arrow from "@icons/blue-arrow.svg"
import TopBar from "./TopBar"
import { H5 } from "../../../styled/headers"
import { gatsbyImage } from "@types"

const Wrappper = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "block" : "none")};
`

const OrdersWrapper = styled.div`
    border: 1px solid var(--light-gray);
    border-radius: 20px;
    margin: 20px 0;
    padding: 30px;
`

const TrackDelivery = styled.a`
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    font-weight: 700;
    color: var(--medium-blue);
    margin-top: 20px;

    svg {
        margin-left: 10px;
        width: 10px;
    }
`

const Orders = ({
    active,
    orders,
    goToCourseText,
    readMoreTrainingText,
    readMoreGadgetText,
    data: {
        deliveryDateText,
        header,
        orderDateText,
        orderNumberText,
        paymentStatusText,
        subheader,
        totalCostText,
        trackDeliveryText,
    },
}: props) => {
    return (
        <Wrappper active={active}>
            <Headline header={header} subheader={subheader} />
            {orders?.length === 0 && (
                <H5
                    style={{ color: "var(--red)", marginTop: "20px" }}
                    dangerouslySetInnerHTML={{ __html: "Brak zamówień" }}
                />
            )}
            {orders?.map(
                ({
                    id,
                    total,
                    deliveryDate,
                    deliveryLink,
                    products,
                    datePaid,
                }) => (
                    <OrdersWrapper>
                        <TopBar
                            id={id}
                            total={total}
                            deliveryDate={deliveryDate}
                            datePaid={datePaid}
                            deliveryDateText={deliveryDateText}
                            orderDateText={orderDateText}
                            orderNumberText={orderNumberText}
                            paymentStatusText={paymentStatusText}
                            totalCostText={totalCostText}
                        />
                        {products?.map(
                            ({ type, image, name, date, place, slug }) => (
                                <Card
                                    type={type}
                                    image={image}
                                    name={name}
                                    date={date}
                                    place={place}
                                    slug={slug}
                                    readMoreTrainingText={readMoreTrainingText}
                                    readMoreGadgetText={readMoreGadgetText}
                                    goToCourseText={goToCourseText}
                                />
                            )
                        )}
                        {deliveryLink && (
                            <TrackDelivery href={deliveryLink}>
                                {trackDeliveryText} <Arrow />
                            </TrackDelivery>
                        )}
                    </OrdersWrapper>
                )
            )}
        </Wrappper>
    )
}

export default Orders

interface props {
    goToCourseText: string
    readMoreTrainingText: string
    readMoreGadgetText: string
    active: boolean
    orders?: Array<{
        deliveryLink?: string
        deliveryDate?: string
        total: string
        datePaid: string
        id: number
        products: Array<{
            type: string
            image: gatsbyImage
            name: string
            date: string
            place: string
            slug: string
        }>
    }>
    data: {
        deliveryDateText: string
        header: string
        menuText: string
        orderDateText: string
        orderNumberText: string
        paymentStatusText: string
        subheader: string
        totalCostText: string
        trackDeliveryText: string
    }
}
