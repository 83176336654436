import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"
import styled from "styled-components"
import { H1 } from "../../styled/headers"
import { breakpoints } from "@breakpoints"
import { GridContainer } from "../../styled/GridContainer"
import { Button } from "../../common/Button"
import ScrollButton from "./ScrollButton"

const StyledWrapper = styled.div`
    min-height: 100vh;
    padding-top: 175px;
    margin-top: -220px;
    margin-bottom: 70px;
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    ${breakpoints.md} {
        align-items: center;
    }
`

const StyledBackgroundImageWrapper = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    > .gatsby-image-wrapper {
        height: 100%;
    }
`

const StyledImageWrapper = styled.div`
    > .gatsby-image-wrapper {
        height: 100%;
        width: calc(100% + 40px);
        margin-left: -20px;
    }

    ${breakpoints.md} {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 70vw;
        max-width: 1400px;
    }
`

const StyledSubheader = styled.div`
    margin-top: 20px;
    font-size: var(--medium-font);

    ${breakpoints.lg} {
        font-size: var(--xlarge-font);
    }

    strong {
        color: var(--light-blue);
        display: inline-block;
    }
`
const StyledText = styled.div`
    margin: 20px 0;

    ${breakpoints.lg} {
        margin: 60px 0;
    }
`

const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
`

const StyledScrollContainer = styled.div`
    position: absolute;
    bottom: -30px;
    left: calc(50% - 30px);
`

const MainBanner = ({
    acf: {
        header,
        subheader,
        text,
        image,
        backgroundImage,
        buttonLink,
        external,
        buttonText,
        scrollText,
    },
}: props) => {
    const endSection = useRef<HTMLInputElement>(null)

    const scrollDown = () => {
        if (endSection && endSection.current) {
            window.scrollTo({
                top: endSection.current.clientHeight - 100,
                behavior: "smooth",
            })
        }
    }

    return (
        <>
            <StyledWrapper ref={endSection}>
                <StyledBackgroundImageWrapper>
                    <GatsbyImage
                        alt=""
                        image={
                            backgroundImage.localFile.childImageSharp
                                .gatsbyImageData
                        }
                    />
                </StyledBackgroundImageWrapper>
                <GridContainer>
                    <div className="grid md:grid-cols-2">
                        <StyledContentWrapper>
                            <H1 dangerouslySetInnerHTML={{ __html: header }} />
                            <StyledSubheader
                                dangerouslySetInnerHTML={{ __html: subheader }}
                            />
                            <StyledText
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                            <Button
                                isLink
                                external={external}
                                icon="arrow"
                                href={buttonLink}
                            >
                                {buttonText}
                            </Button>
                        </StyledContentWrapper>
                        <StyledImageWrapper>
                            <GatsbyImage
                                alt="narciarz"
                                image={
                                    image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </StyledImageWrapper>
                    </div>
                </GridContainer>
            </StyledWrapper>
            <StyledScrollContainer>
                <ScrollButton text={scrollText} onClick={scrollDown} />
            </StyledScrollContainer>
        </>
    )
}

export default MainBanner

interface props {
    acf: {
        header: string
        subheader: string
        text: string
        image: gatsbyImage
        backgroundImage: gatsbyImage
        buttonLink: string
        external: boolean
        buttonText: string
        scrollText: string
    }
}
