import React from "react"
import { Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { H4 } from "../../../styled/headers"
import { gatsbyImage } from "@types"
import Arrow from "@icons/bronze-arrow.svg"
import { routes } from "@routes"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    border-bottom: 1px solid var(--light-gray);

    &:last-child {
        border-bottom: none;
    }

    ${breakpoints.md} {
        flex-direction: row;
    }

    .gatsby-image-wrapper {
        min-width: 100%;
        margin-right: 20px;

        ${breakpoints.md} {
            min-width: 300px;
        }

        img {
            height: auto;
        }
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

const Date = styled.div`
    font-weight: 700;
    margin: 10px 0;
    color: var(--light-blue);
`

const Place = styled.div`
    color: var(--gray);
    font-size: var(--small-font);
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
`

const linkStyles = css`
    display: flex;
    align-items: center;
    font-weight: 700;
    color: var(--bronze);
    font-size: var(--small-font);

    ${breakpoints.md} {
        font-size: var(--medium-font);
    }

    svg {
        margin-left: 10px;
        width: 10px;
    }
`
const StyledLink = styled(props => <Link {...props} />)`
    ${linkStyles}
`

const KnowledgeBaseLink = styled.div`
    ${linkStyles}
    margin-left: auto;
    margin-top: 20px;
    cursor: pointer;
`

const Card = ({
    type,
    name,
    date,
    place,
    slug,
    image,
    goToCourseText,
    readMoreTrainingText,
    readMoreGadgetText,
}: props) => {
    const isGadget = type === "gadget"
    return (
        <Wrapper>
            <GatsbyImage
                alt={name}
                image={image?.localFile?.childImageSharp?.gatsbyImageData}
            />
            <Content>
                <Date dangerouslySetInnerHTML={{ __html: date }} />
                <H4 dangerouslySetInnerHTML={{ __html: name }} />
                <Place dangerouslySetInnerHTML={{ __html: place }} />
                <StyledLink to={slug}>
                    {isGadget ? readMoreGadgetText : readMoreTrainingText}
                    <Arrow />
                </StyledLink>
                {!isGadget && (
                    <KnowledgeBaseLink
                        onClick={() => {
                            navigate(routes.knowledgeBase, {
                                state: { title: name },
                            })
                        }}
                    >
                        {goToCourseText}
                        <Arrow />
                    </KnowledgeBaseLink>
                )}
            </Content>
        </Wrapper>
    )
}

export default Card

interface props {
    readMoreTrainingText: string
    readMoreGadgetText: string
    goToCourseText: string
    image: gatsbyImage
    name: string
    date: string
    place: string
    slug: string
    type: string
}
