import React from "react"
import styled from "styled-components"
import { H4 } from "../../../styled/headers"
import { useBasicQuery } from "@useBasicQuery"

const Email = styled.a`
    color: var(--light-blue);
`

const WriteToUs = styled.div`
    margin-top: 20px;
`

const Informations = () => {
    const {
        contact: {
            acf: { email, telephone, writeToUsText, callUsText },
        },
    } = useBasicQuery()
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: callUsText }} />
            <H4 dangerouslySetInnerHTML={{ __html: telephone }} />
            <WriteToUs dangerouslySetInnerHTML={{ __html: writeToUsText }} />
            <Email
                href={`mailto:${email}`}
                dangerouslySetInnerHTML={{ __html: email }}
            />
        </>
    )
}

export default Informations
