import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { gatsbyImage } from "@types"
import styled from "styled-components"
import { H3 } from "../../styled/headers"

const LogoLink = styled.a`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .gatsby-image-wrapper {
        border: 1px solid var(--gray-light);
    }
`

const Logo = ({ image, link, description }: props) => {
    return (
        <div>
            <LogoLink
                aria-label={link}
                href={link}
                target="_blank"
                rel="noopener"
            >
                <GatsbyImage
                    alt={description}
                    image={image.localFile.childImageSharp.gatsbyImageData}
                />
            </LogoLink>
            <H3 small dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    )
}

export default Logo

interface props {
    image: gatsbyImage
    link: string
    description: string
}
