import React from "react"
import Template from "../Template"
import SectionParser from "./section-parser/SectionsParser"
import { Helmet } from "react-helmet"

const PageTemplate = ({
    pageContext: {
        acf: { title, description },
        templates: { nodes },
        isFrontPage,
    },
}: props) => {
    return (
        <Template isFrontPage={isFrontPage}>
            <Helmet
                htmlAttributes={{
                    lang: "pl-PL",
                }}
                title={title}
                meta={[
                    {
                        name: `description`,
                        content: description,
                    },
                ]}
            />
            <SectionParser templates={nodes} />
        </Template>
    )
}

export default PageTemplate

interface props {
    pageContext: {
        acf: {
            title?: string
            description?: string
        }
        templates: {
            nodes: any
        }
        isFrontPage: boolean
    }
}
